<template>
  <div>
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              Administration
            </div>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              @click="[(search = ''), handleFilterEmployees()]"
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              v-model="search"
              @input="handleFilterEmployees()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Employee Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li>

        <li
          class="icon"
          :class="{ hidden: !warnings.length }"
          @click="handleShowWarningsDialog"
        >
          <a href="#">
            <span class="tooltip">{{ `Warnings ${warnings.length}` }}</span>
            <span
              ><i class="fa-solid fa-triangle-exclamation text-orange-500"></i
            ></span>
          </a>
        </li>

        <li @click="getVaccinationRecordsCsv" class="icon">
          <a href="#">
            <span class="tooltip">Vaccination Records</span>
            <span><i class="fa-solid fa-syringe text-red-500"></i></span>
          </a>
        </li>

        <li class="icon" @click="showNewEmployeeDialog = true">
          <a href="#">
            <span class="tooltip">Add Employee</span>
            <span><i class="fa-solid fa-user-plus text-blue-500"></i></span>
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate ml-2"></i></span>
          </a>
        </li>

        <li>
          <a href="#">
            <div class="txt-sm text-white mr-5 cursor-default">
              {{ `Employees: ${formatNumber(employees.length)}` }}
            </div>
          </a>
        </li>
      </ul>
    </nav>

    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <!--  NEW EMPLOYEE DIALOG -->
    <r-modal
      v-if="showNewEmployeeDialog"
      @close="showNewEmployeeDialog = false"
    >
      <div
        v-if="showNewEmployeeDialog"
        class="p-5 flex flex-col justify-between text-sm px-3"
      >
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">New Employee Profile</div>
          <button @click="showNewEmployeeDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <!-- Section -->
        <div>
          <div>
            <p class="text-sm text-gray-500">Employee UPN</p>
            <input
              class="block w-full rounded-md border-0 py-2 text-start text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              :class="{ missing: !userPrincipalName }"
              type="text"
              maxlength="200"
              v-model="userPrincipalName"
            />
          </div>

          <p class="text-base text-gray-500 my-1">
            Enter the employee's UPN (User Principal Name) as it appears on the
            company's Active Directory
          </p>
        </div>

        <!-- Footer -->
        <div class="flex justify-end mt-1">
          <button
            v-if="userPrincipalName"
            @click="handleSearchActiveDirectory"
            class="btn bg-blue-500 text-white"
          >
            Search Active Directory
          </button>
        </div>
      </div>
    </r-modal>

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div v-if="showFiltersDialog" class="p-5">
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Employee Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          class="flex flex-col justify-between text-sm px-3 max-h-[300px] lg:max-h-[500px] overflow-x-auto"
        >
          <!-- Section -->
          <div class="px-5">
            <!-- Filter by Status -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex my-1 px-5 pb-2">
                <Listbox
                  as="div"
                  v-model="selectedEmployeeStatus"
                  class="mb-10"
                >
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees based on Status</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{
                        selectedEmployeeStatus
                      }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-80 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in employeeStatusOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedEmployeeStatus }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedEmployeeStatus
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedEmployeeStatus"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Company Name -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByCompany"
                  id="filterByCompany"
                  aria-describedby="filter-description"
                  name="filterByCompany"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="filterByCompany" class="font-medium text-gray-900"
                    >Filter by Company</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByCompany">
                <Listbox as="div" v-model="selectedCompany" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees from this Company</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{ selectedCompany }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in companyOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedCompany }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedCompany
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedCompany"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Job Title -->
            <div class="border-b border-gray-400 mb-5">
              <div class="mb-2">
                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="filterByJobTitle"
                    id="filterByJobTitle"
                    aria-describedby="filter-description"
                    name="filterJobTitle"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-base leading-6">
                    <label
                      for="filterByJobTitle"
                      class="font-medium text-gray-900"
                      >Filter by Job Title</label
                    >
                    {{ " " }}
                  </div>
                </div>

                <div class="flex mt-2" v-if="filterByJobTitle">
                  <div
                    v-if="selectedJobTitles.length !== jobTitles.length"
                    @click="selectedJobTitles = [...jobTitles]"
                    class="cursor-pointer text-base underline text-blue-500"
                  >
                    Selected All
                  </div>
                  <div
                    v-if="selectedJobTitles.length"
                    @click="selectedJobTitles = []"
                    class="ml-2 cursor-pointer text-base underline text-red-500"
                  >
                    Clear Selected
                  </div>
                </div>
              </div>

              <div v-if="filterByJobTitle">
                <div class="relative shadow-md">
                  <table
                    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    <tbody>
                      <tr
                        v-for="jobTitle in jobTitles"
                        :key="jobTitle.name"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td class="w-4 p-4">
                          <div class="flex items-center">
                            <input
                              v-model="selectedJobTitles"
                              :id="jobTitle.name"
                              :value="jobTitle"
                              type="checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                        <td
                          scope="row"
                          class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {{ jobTitle }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="flex justify-end mt-1">
            <button
              @click="handleSaveFilters"
              class="btn bg-blue-500 text-white"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- WARNINGS -->
    <r-modal v-if="showWarningsDialog" @close="showWarningsDialog = false">
      <div class="border-l-8 border-2 border-red-500">
        <!-- TITLE -->
        <div class="flex justify-between items-end p-3">
          <div class="text-2xl font-bold mb-2">Warnings</div>
          <i
            class="fa-solid fa-print text-gray-500 cursor-pointer"
            @click="printWarnings"
          ></i>
        </div>

        <div
          class="max-h-[300px] lg:max-h-[500px] relative overflow-x-auto my-3"
        >
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('employeeName')"
                >
                  Employee
                </th>
                <th
                  style="width: 10%"
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('type')"
                >
                  Type
                </th>
                <th
                  style="width: 20%"
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('level')"
                >
                  Level
                </th>
                <th
                  style="width: 50%"
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortWarningsByKey('message')"
                >
                  Message
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="warning in warnings"
                :key="warning._id"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ warning.employeeName }}
                </td>
                <td class="px-6 py-2">{{ warning.type }}</td>
                <td class="px-6 py-2">
                  {{ warning.level }}
                </td>

                <td class="px-6 py-2">
                  {{ warning.message }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex justify-between text-base my-2 px-3">
          <div></div>
          <button
            class="btn bg-blue-500 text-white"
            @click="handleAcknowledgeWarnings"
          >
            Acknowledge
          </button>
        </div>
      </div>
    </r-modal>

    <div v-else>
      <div v-if="employees.length">
        <!-- Data Table -->
        <div class="relative overflow-x-auto">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('surname')"
                >
                  Last Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('givenName')"
                >
                  First Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('company')"
                >
                  Company
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('jobTitle')"
                >
                  Job Title
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('securityGroupName')"
                >
                  Security Group
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('gatewayCode')"
                >
                  Gateway
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('mobilePhone')"
                >
                  Mobile
                </th>
                <th
                  scope="col"
                  class="px-6 py-2 cursor-pointer"
                  @click="sortArrayByKey('mail')"
                >
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="employee in employees"
                :key="employee._id"
                @click="handleSelectEmployee(employee._id)"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ employee.surname }}
                </td>
                <td>{{ employee.givenName }}</td>
                <td class="px-6 py-2">{{ employee.companyName }}</td>
                <td class="px-6 py-2">{{ employee.jobTitle }}</td>
                <td class="px-6 py-2">{{ employee.securityGroup.name }}</td>
                <td class="px-6 py-2">{{ employee.gatewayCode }}</td>
                <td class="px-6 py-2">{{ employee.mobilePhone }}</td>
                <td class="px-6 py-2">{{ employee.mail }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="bottom-frame text-base">
        <div class="p-3 text-xl text-red-500">
          No employees found with the selected filters.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import msalInstance from "../services/msal";
import axios from "axios";
import { mixin } from "../mixins/mixin";
import exportFromJSON from "export-from-json";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";

export default {
  name: "administration",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      loading: false,

      inactivityTimer: null,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      allEmployees: [],
      employees: [],

      search: "",

      showWarningsDialog: false,

      warnings: [],

      showNewEmployeeDialog: false,
      userPrincipalName: null,

      showFiltersDialog: false,
      filterByCompany: false,
      filterByJobTitle: false,

      selectedEmployeeStatus: "Active Only",
      employeeStatusOptions: ["Active Only", "All Employees"],
      selectedCompany: null,
      selectedJobTitles: [],

      selectedAircraftDeck: "Cargo and Pax.",
      aircraftDeckOptions: [
        "Cargo and Pax.",
        "Cargo Aircraft",
        "Passenger Aircraft",
      ],
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },

    companyFilter() {
      return this.$store.getters.companyFilter;
    },

    jobTitles() {
      if (this.auth) {
        return [...this.user.securityGroup.jobTitles];
      } else {
        return [];
      }
    },

    jobTitlesFilter() {
      return this.$store.getters.jobTitlesFilter;
    },

    companyOptions() {
      if (this.auth) {
        return [...this.user.securityGroup.companies];
      } else {
        return [];
      }
    },

    user() {
      return this.$store.state.user;
    },

    board() {
      return this.$store.getters.board;
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
        }
        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;
      }
    },

    sortWarningsByKey(key) {
      const sortedAsc = this.isSortedAsc(this.warnings, key);

      if (sortedAsc) {
        this.warnings.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.warnings.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    async handleSearchActiveDirectory() {
      this.showNewEmployeeDialog = false;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        let res = await api.get(`/employees/upn/${this.userPrincipalName}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        if (res.data.count > 0) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = `Profile cannot be added. An employee with User Principal Name ${this.userPrincipalName} already exists.`;
          this.snackbarVisible = true;
          return;
        }

        const scopes = ["User.Read.All"];

        await msalInstance.initialize();
        const tokenResult = await msalInstance.acquireTokenSilent({
          scopes,
        });

        res = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${this.userPrincipalName}`,
          {
            headers: {
              Authorization: "Bearer " + tokenResult.accessToken,
            },
          }
        );

        this.loading = false;

        const missingFields = [];

        if (!res.data.id) missingFields.push("Azure Id");
        if (!res.data.userPrincipalName)
          missingFields.push("User Principal Name");
        if (!res.data.displayName) missingFields.push("Display Name");
        if (!res.data.givenName) missingFields.push("First Name");
        if (!res.data.surname) missingFields.push("Last Name");
        if (!res.data.jobTitle) missingFields.push("Job Title");
        if (!res.data.mail) missingFields.push("Email");
        // if (!res.data.mobilePhone) missingFields.push("Mobile Phone");

        if (missingFields.length) {
          this.userPrincipalName = null;
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = `Profile cannot be added. The following required information is missing on Active Directory: ${missingFields.toString()}`;
          this.snackbarVisible = true;
          return;
        }

        const employee = {
          azureId: res.data.id,
          userPrincipalName: res.data.userPrincipalName,
          companyId: {
            number: "",
            expirationDate: new Date(
              new Date().getTime() + 365 * 24 * 60 * 60 * 1000
            )
              .toISOString()
              .substring(0, 10),
            image: "employee-id-default.jpeg",
          },
          displayName: res.data.displayName,
          givenName: res.data.givenName,
          surname: res.data.surname,
          jobTitle: this.user.securityGroup.jobTitles.includes(
            res.data.jobTitle
          )
            ? res.data.jobTitle
            : null,
          companyName: "Atlas Air",
          dateOfHire: new Date().toISOString().substring(0, 10),
          seniorityNumber: 1,
          mail: res.data.mail,
          mobilePhone: res.data.mobilePhone,
          address: {
            street: "",
            city: "",
            state: "FL",
            country: "United States",
            postalCode: "",
          },
          emergencyContact: {
            name: "",
            number: "",
          },
        };

        this.$store.commit("updateEmployee", employee);
        this.$router.push({ name: "employeeCreate" });
      } catch (error) {
        let msg =
          "An error ocurred while trying to get data from Active Directory.";
        if (error.message.includes("404")) {
          msg = `Employee not found with UPN ${this.userPrincipalName}`;
        }

        this.userPrincipalName = null;
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = msg;
        this.snackbarVisible = true;
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.employees, key);

      if (sortedAsc) {
        this.employees.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.employees.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleOpenFilters() {
      if (!this.selectedCompany) {
        this.selectedCompany = this.user.companyName.slice();
      }

      if (this.companyFilter.active) {
        this.filterByCompany = true;
        this.selectedCompany = this.companyFilter.name.slice();
      }

      if (this.user.jobTitlesFilter.active) {
        this.filterByJobTitle = true;
        this.selectedJobTitles = [...this.user.jobTitlesFilter.jobTitles];
      } else {
        this.filterByJobTitle = false;
        this.selectedJobTitles = [];
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //------------------------------------Airport Filter
      let assignmentsAirportFilter = {
        active: false,
        match: "",
        iataCode: "",
      };

      if (this.filterByAirport) {
        if (this.selectedIataCode?.length === 3) {
          const i = this.airports.findIndex((item) => {
            return item.iata === this.selectedIataCode.toUpperCase();
          });

          if (i >= 0) {
            assignmentsAirportFilter = {
              active: true,
              match: this.selectedEmployeeStatus.slice(),
              iataCode: this.selectedIataCode.slice().toUpperCase(),
            };
          } else {
            this.filterByAirport = false;
            this.selectedIataCode = null;
          }
        } else {
          this.filterByAirport = false;
        }
      }

      this.$store.commit(
        "updateAssignmentsAirportFilter",
        assignmentsAirportFilter
      );

      //-----------------------------Company Filter
      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.filterByCompany && this.selectedCompany) {
        companyFilter = {
          active: true,
          name: this.selectedCompany.slice(),
        };
      }

      this.$store.commit("updateCompanyFilter", companyFilter);

      //------------------------------Job Titles Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.filterByJobTitle && this.selectedJobTitles.length) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.selectedJobTitles],
        };
      }

      this.$store.commit("updateJobTitlesFilter", jobTitlesFilter);

      this.getData();
    },

    handleFilterEmployees() {
      if (this.search.length) {
        this.employees = this.allEmployees.filter((employee) => {
          return (
            employee.surname
              .toUpperCase()
              .startsWith(this.search.toUpperCase()) ||
            employee.givenName
              .toUpperCase()
              .startsWith(this.search.toUpperCase())
          );
        });
      } else {
        this.employees = [...this.allEmployees];
      }
    },

    handleShowWarningsDialog() {
      if (this.warnings.length) {
        this.showWarningsDialog = true;
      }
    },

    async printWarnings() {
      if (!this.warnings.length) {
        this.snackbarColor = "red";
        this.snackbarText = "No warnings to print";
        this.snackbarVisible = true;
        return;
      }
      try {
        const pdfDoc = await PDFDocument.create();
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaBoldFont = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );
        let text;
        let textWidth;
        text = `Employee Warnings Report`;
        pdfDoc.setTitle(text);
        pdfDoc.addPage();
        let warning;
        let rowHeight = 70;
        let pages = pdfDoc.getPages();
        let page = pages[0];
        let options = {
          x: 50,
          y: 775,
          size: 20,
          font: helveticaBoldFont,
          color: rgb(0, 0, 0),
        };
        const pageCenter = parseInt(page.getWidth() / 2);
        textWidth = helveticaBoldFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        //Printed On
        options.y -= 18;
        options.size = 10;
        options.font = helveticaFont;
        text = `Printed: ${this.formatDateTime(new Date())}`;
        textWidth = helveticaFont.widthOfTextAtSize(text, options.size);
        options.x = pageCenter - parseInt(textWidth / 2);
        page.drawText(text, options);
        options.y -= 40;
        options.x = 50;
        for (let i = 0; i < this.warnings.length; i++) {
          warning = this.warnings[i];
          options.color = rgb(0.15, 0.09, 0.81);
          // Employee Name.
          //---------------------------------------------------ROW 1
          options.x = 50;
          options.font = helveticaFont;
          options.color = rgb(0, 0, 1); //Blue
          options.size = 12;
          page.drawText(`${warning.employeeName}`, options);
          options.size = 10;
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          //---------------------------------------------------ROW 2
          // Type
          options.y -= 15;
          page.drawText(`${warning.type}`, options);
          //---------------------------------------------------ROW 3
          options.y -= 15;
          //Message
          page.drawText(`${warning.message}`, options);
          //---------------------------------------------------Separator
          options.color = rgb(0.17, 0.24, 0.31); //Gray
          options.y -= 10;
          page.drawText(
            `__________________________________________________________________________________`,
            options
          );
          options.y -= 20;

          if (options.y - 70 < rowHeight && i <= this.warnings.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );
            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);
            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.color = rgb(0.15, 0.09, 0.81);
            options.x = 50;
            options.y = 775;
          }
        }
        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0); //Black
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);
        options.x = 450;
        page.drawText(`page ${pages.length} of ${pdfDoc.pageCount}`, options);
        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatDateShort(miliseconds) {
      const date = new Date(miliseconds);

      let options = {
        timeZone: "UTC",
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    },

    handleAcknowledgeWarnings() {
      //NEXT handle acknowledgement
      this.showWarningsDialog = false;
    },

    async getData() {
      this.employees = [];
      this.warnings = [];

      let activeOnly = true;

      if (this.selectedEmployeeStatus === "All Employees") {
        activeOnly = false;
      }

      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.companyFilter.active) {
        companyFilter = {
          active: true,
          name: this.companyFilter.name.slice(),
        };
      }

      //--------------------------Job Title Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.jobTitlesFilter.active) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.jobTitlesFilter.jobTitles],
        };
      }

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.post(
          "/employees/employees",
          {
            companyFilter,
            jobTitlesFilter,
            aircraftDeck: this.selectedAircraftDeck,
            activeOnly,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status === 200) {
          let employees = res.data.employees;

          employees.forEach((e) => {
            e.securityGroupName = e.securityGroup.name;
          });

          this.allEmployees = [...employees];

          this.warnings = this.getGeneralWarnings(this.allEmployees);
          this.handleFilterEmployees();
        } else {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;

          setTimeout(() => {
            this.snackbarVisible = false;
          }, 3000);
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error.message;
        this.snackbarVisible = true;

        setTimeout(() => {
          this.snackbarVisible = false;
        }, 3000);
      }
    },

    downloadCsvFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    async getVaccinationRecordsCsv() {
      if (this.loading) {
        return;
      }

      try {
        const fileName = "vaccines_records";
        const exportType = exportFromJSON.types.csv;
        const data = [];
        let row;
        let vaccine;

        const employees = this.employees.filter((item) => {
          return item.vaccines?.length;
        });

        employees.forEach((employee) => {
          row = {
            EmployeeID: employee.companyId.number,
            FirstName: employee.givenName,
            LastName: employee.surname,
            Email: employee.mail,
            Phone: employee.mobilePhone,
          };

          for (let i = 0; i < 10; i++) {
            vaccine = employee.vaccines[i];

            switch (i) {
              case 0:
                if (vaccine) {
                  row.Vaccine1ProperName = vaccine.properName;
                  row.Vaccine1Type = vaccine.type;
                  row.Vaccine1Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine1ProperName = "";
                  row.Vaccine1Type = "";
                  row.Vaccine1Date = "";
                }

                break;

              case 1:
                if (vaccine) {
                  row.Vaccine2ProperName = vaccine.properName;
                  row.Vaccine2Type = vaccine.type;
                  row.Vaccine2Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine2ProperName = "";
                  row.Vaccine2Type = "";
                  row.Vaccine2Date = "";
                }

                break;

              case 2:
                if (vaccine) {
                  row.Vaccine3ProperName = vaccine.properName;
                  row.Vaccine3Type = vaccine.type;
                  row.Vaccine3Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine3ProperName = "";
                  row.Vaccine3Type = "";
                  row.Vaccine3Date = "";
                }

                break;
              case 3:
                if (vaccine) {
                  row.Vaccine4ProperName = vaccine.properName;
                  row.Vaccine4Type = vaccine.type;
                  row.Vaccine4Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine4ProperName = "";
                  row.Vaccine4Type = "";
                  row.Vaccine4Date = "";
                }

                break;
              case 4:
                if (vaccine) {
                  row.Vaccine5ProperName = vaccine.properName;
                  row.Vaccine5Type = vaccine.type;
                  row.Vaccine5Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine5ProperName = "";
                  row.Vaccine5Type = "";
                  row.Vaccine5Date = "";
                }

                break;
              case 5:
                if (vaccine) {
                  row.Vaccine6ProperName = vaccine.properName;
                  row.Vaccine6Type = vaccine.type;
                  row.Vaccine6Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine6ProperName = "";
                  row.Vaccine6Type = "";
                  row.Vaccine6Date = "";
                }

                break;
              case 6:
                if (vaccine) {
                  row.Vaccine7ProperName = vaccine.properName;
                  row.Vaccine7Type = vaccine.type;
                  row.Vaccine7Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine7ProperName = "";
                  row.Vaccine7Type = "";
                  row.Vaccine7Date = "";
                }

                break;
              case 7:
                if (vaccine) {
                  row.Vaccine8ProperName = vaccine.properName;
                  row.Vaccine8Type = vaccine.type;
                  row.Vaccine8Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine8ProperName = "";
                  row.Vaccine8Type = "";
                  row.Vaccine8Date = "";
                }

                break;
              case 8:
                if (vaccine) {
                  row.Vaccine9ProperName = vaccine.properName;
                  row.Vaccine9Type = vaccine.type;
                  row.Vaccine9Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine9ProperName = "";
                  row.Vaccine9Type = "";
                  row.Vaccine9Date = "";
                }

                break;
              case 9:
                if (vaccine) {
                  row.Vaccine10ProperName = vaccine.properName;
                  row.Vaccine10Type = vaccine.type;
                  row.Vaccine10Date = this.formatDate(vaccine.date);
                } else {
                  row.Vaccine10ProperName = "";
                  row.Vaccine10Type = "";
                  row.Vaccine10Date = "";
                }

                break;

              default:
                break;
            }
          }

          data.push(row);
        });

        exportFromJSON({ data, fileName, exportType });
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    handleAddEmployee() {
      this.$router.push({ name: "employeeCreate" });
    },

    handleSelectEmployee(_id) {
      this.$router.push({
        name: "employeeUpdate",
        params: { id: _id },
      });
    },
  },
};
</script>

<style scoped>
.fa-chevron-left {
  font-size: 1.3rem;
  color: white;
}
</style>
