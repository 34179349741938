<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon" @click="handleGoBack">
          <a href="#">
            <span class="tooltip">Go Back</span>
            <span><i class="fa-solid fa-arrow-left text-white"> </i></span>
          </a>
        </li>

        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-3 text-base lg:text-2xl text-white">
              My Activity Report
            </div>
          </a>
        </li>

        <!-- <li>
          <a href="#">
            <button class="btn text-white">
              {{
                `${employee.surname}, ${employee.givenName.substring(
                  0,
                  1
                )} - ${formatMonth(this.monthFilter.month)} - ${
                  this.monthFilter.year
                } - ${this.monthFilter.period} Period`
              }}
            </button>
          </a>
        </li> -->

        <li></li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- LOADING -->
    <r-spinner v-if="loading"> </r-spinner>

    <div class="mx-5">
      <div class="flex justify-between my-2 border-b border-gray-400 mb-5 p-2">
        <div class="text-2xl ml-2">
          {{
            `${employee.surname}, ${employee.givenName} - ${employee.companyId.number}`
          }}
        </div>

        <div class="text-2xl mr-3">
          {{
            `${formatMonth(monthFilter.month)} - ${monthFilter.year}-${
              monthFilter.period
            } Period`
          }}
        </div>
      </div>

      <div class="border border-gray-500 rounded-md p-3 mb-2 bg-green-200">
        <div class="columns-3">
          <div>
            Work Days 01 - 15:
            <span class="font-bold">
              {{ employee.activity.workDaysFirstHalf }}</span
            >
          </div>

          <div>
            {{ `Work Days ${this.secondPeriod}:` }}
            <span class="font-bold"
              >{{ employee.activity.workDaysSecondHalf }}
            </span>
          </div>

          <div>
            Work Days Total:
            <span class="font-bold">
              {{ employee.activity.workDays }}
            </span>
          </div>
        </div>

        <div class="columns-3">
          <div>
            Vacation:
            <span class="font-bold"> {{ employee.activity.vacationDays }}</span>
          </div>

          <div>
            Floating Holidays:<span class="font-bold"
              >{{ employee.activity.floatingHolidayDays }}
            </span>
          </div>

          <div>
            Holidays:
            <span class="font-bold">
              {{ employee.activity.holidayDays }}
            </span>
          </div>
        </div>

        <div class="columns-3">
          <div>
            Sick Days:
            <span class="font-bold">{{ employee.activity.sickDays }} </span>
          </div>

          <div>
            LOA:
            <span class="font-bold"> {{ employee.activity.loaDays }}</span>
          </div>

          <div>
            Leave:
            <span class="font-bold">{{ employee.activity.leaveDays }} </span>
          </div>
        </div>

        <div class="columns-3">
          <div>
            TOTAL DAYS:
            <span class="font-bold">{{ employee.activity.totalDays }} </span>
          </div>

          <div>
            Perdiem :
            <span class="font-bold">{{ employee.activity.perdiemDays }} </span>
          </div>
        </div>

        <div class="columns-3">
          <div>
            Days OFF:
            <span class="font-bold">{{ employee.activity.daysOff }} </span>
          </div>

          <div>
            STBY:
            <span class="font-bold">{{ employee.activity.standByDays }} </span>
          </div>
        </div>

        <div class="columns-3">
          <div>
            Mission Hazardous:<span class="font-bold text-red-500">
              {{ employee.activity.missionHazard }}
            </span>
          </div>

          <div>
            COVID 19 Days:
            <span class="font-bold text-red-500">
              {{ employee.activity.healthRiskDays }}</span
            >
          </div>

          <div>
            COVID 19 Allowance:
            <span class="font-bold text-red-500">
              {{ employee.activity.healthRiskAllowance }}</span
            >
          </div>
        </div>
        <!--  v-if="this.canReadEmployeePayrollReport" -->
        <!-- <div
         Removed 09DEC2024
        >
          <div class="columns-3">
            <div>
              Perdiem P-1:
              <span class="font-bold ml-1"
                >{{ employee.activity.perdiemDaysFirstHalf }}
              </span>
            </div>

            <div>
              Perdiem P-2:
              <span class="font-bold ml-1"
                >{{ employee.activity.perdiemDaysSecondHalf }}
              </span>
            </div>

            <div>
              Total Perdiem :
              <span class="font-bold ml-2"
                >{{ employee.activity.perdiemDays }}
              </span>
            </div>
          </div>

          <div class="columns-3">
            <div>
              Days over 20:
              <span class="font-bold">{{ employee.activity.daysOver20 }} </span>
            </div>

            <div>
              Days over Guarrantee:
              <span class="font-bold"
                >{{ employee.activity.daysOverGuarrantee }}
              </span>
            </div>

            <div>
              Hours over Guarrantee:
              <span class="font-bold"
                >{{ employee.activity.daysOverGuarrantee * 8 }}
              </span>
            </div>
          </div>
        </div> -->

        <div
          v-if="canUpdateEmployeePayrollReport"
          class="flex justify-between mt-3 border-t border-gray-500 p-2"
        >
          <div class="flex h-6 items-center mb-2">
            <input
              v-model="confirmed"
              id="reportConfirmed"
              aria-describedby="report-confirmed"
              name="reportConfirmed"
              type="checkbox"
              class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
            />

            <div class="ml-3 text-base leading-6">
              <label for="reportConfirmed" class="font-medium text-gray-900"
                >I Confirm that this report is accurate</label
              >
              {{ " " }}
            </div>
          </div>

          <div class="flex justify-end">
            <button
              @click="printActivityReport(employee)"
              class="btn bg-blue-500 text-white"
            >
              Print Report
            </button>

            <button
              @click="handleSubmitReport"
              class="btn bg-blue-500 text-white ml-2"
            >
              Submit Report
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";

export default {
  name: "myActivityReport",
  mixins: [mixin],
  components: {
    RSnackbar,
    RSpinner,
  },

  data() {
    return {
      loading: false,

      inactivityTimer: null,

      dayDetailsDialog: false,
      dateData: null,
      boardStyle: "Reports",
      boardStyleOptions: ["Flow Chart", "Reports"],
      selectedBoardStyle: "Reports",
      activityReports: [],
      confirmationDialog: false,
      confirmation: null,
      reportRemarks: "",
      remarksRules: [(v) => v.length < 400 || "Maximum 400 characters"],
      perdiemAdjustment: 0,
      internationalPerdiemAdjustment: 0,
      missionHazardAdjustment: 0,
      healthRiskAdjustment: 0,
      adjustedMissionHazard: 0,
      adjustedHealthRisk: 0,

      confirmed: false,
      reportSummary: null,

      employeeDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      selectedReport: null,
    };
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  beforeDestroy() {
    clearTimeout(this.inactivityTimer);
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },

    employee() {
      return this.$store.state.employee;
    },

    monthFilter() {
      return this.$store.getters.monthFilter;
    },

    secondPeriod() {
      return `16 - ${this.monthFilter.days.length}`;
    },

    topPanelRight() {
      return this.$refs["topPanelRight"];
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    adjustedPerdiemDaysSecondHalf() {
      //DOMESTIC PERDIEM
      let t = 0;

      if (!isNaN(this.perdiemAdjustment)) {
        t = parseInt(this.perdiemAdjustment);
      }

      if (isNaN(t)) {
        return parseInt(this.employee.activity.perdiemDaysSecondHalf);
      } else {
        return (
          parseInt(t) + parseInt(this.employee.activity.perdiemDaysSecondHalf)
        );
      }
    },

    adjustedInternationalPerdiemDaysSecondHalf() {
      //INTERNATIONAL PERDIEM
      let t = 0;

      if (!isNaN(this.internationalPerdiemAdjustment)) {
        t = parseInt(this.internationalPerdiemAdjustment);
      }

      if (isNaN(t)) {
        return parseInt(
          this.employee.activity.internationalPerdiemDaysSecondHalf
        );
      } else {
        return (
          parseInt(t) +
          parseInt(this.employee.activity.internationalPerdiemDaysSecondHalf)
        );
      }
    },

    adjustedDaysOverGuarrantee() {
      let v1 = 0;
      let v2 = 0;
      let t = 0;

      if (!isNaN(this.missionHazardAdjustment)) {
        v1 = parseInt(this.missionHazardAdjustment);
      }

      if (!isNaN(this.healthRiskAdjustment)) {
        v2 = parseFloat(this.healthRiskAdjustment);
      }

      t = v1 + v2;

      if (isNaN(t)) {
        return parseFloat(this.employee.activity.daysOverGuarrantee);
      } else {
        return (
          parseFloat(t) + parseFloat(this.employee.activity.daysOverGuarrantee)
        );
      }
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
        }

        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;
      }
    },

    handleGoBack() {
      this.$router.go(-1);
    },

    async deleteActivityReport(_id) {
      this.confirmationDialog = false;
      this.activityReportDialog = false;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.delete(`/reports/monthly-activity/${_id}`, {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
          return;
        }

        this.activityReport = null;
      } catch (error) {
        this.activityReport = null;
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    async handleSubmitReport() {
      if (!this.confirmed) {
        this.snackbarColor = "#FF0000";
        this.snackbarText = `Please confirm that this report is accurate.`;
        this.snackbarVisible = true;
        return;
      }

      const dt = new Date(this.monthFilter.year, this.monthFilter.month, 1);

      const month = dt.getUTCMonth();

      let report = {
        employee: this.employee,
        year: this.monthFilter.year,
        month,
        period: this.monthFilter.period,
        daysInThisMonth: this.employee.activity.daysInThisMonth,
        daysOff: this.employee.activity.daysOff,
        daysOver20: this.employee.activity.daysOver20,
        daysOverGuarrantee: this.employee.activity.daysOverGuarrantee,
        floatingHolidayDays: this.employee.activity.floatingHolidayDays,
        healthRiskAllowance: this.employee.activity.healthRiskAllowance,
        healthRiskDays: this.employee.activity.healthRiskDays,
        holidayDays: this.employee.activity.holidayDays,
        leaveDays: this.employee.activity.leaveDays,
        loaDays: this.employee.activity.loaDays,
        missionHazard: this.employee.activity.missionHazard,

        //Calculated Perdiem
        perdiemDays: this.employee.activity.perdiemDays,
        perdiemDaysFirstHalf: this.employee.activity.perdiemDaysFirstHalf,
        perdiemDaysSecondHalf: this.employee.activity.perdiemDaysSecondHalf,

        //Adjustment added by user
        perdiemAdjustment: this.perdiemAdjustment,
        internationalPerdiemAdjustment: this.internationalPerdiemAdjustment,

        //Total Ajusted Perdiem
        adjustedPerdiemDaysSecondHalf: this.adjustedPerdiemDaysSecondHalf,

        internationalPerdiemDays:
          this.employee.activity.internationalPerdiemDays,
        internationalPerdiemDaysFirstHalf:
          this.employee.activity.internationalPerdiemDaysFirstHalf,
        internationalPerdiemDaysSecondHalf:
          this.employee.activity.internationalPerdiemDaysSecondHalf,

        //Total Ajusted International Perdiem
        adjustedInternationalPerdiemDaysSecondHalf:
          this.adjustedInternationalPerdiemDaysSecondHalf,

        sickDays: this.employee.activity.sickDays,
        totalDays: this.employee.activity.totalDays,
        vacationDays: this.employee.activity.vacationDays,
        workDays: this.employee.activity.workDays,
        standByDays: this.employee.activity.standByDays,
        workDaysFirstHalf: this.employee.activity.workDaysFirstHalf,
        workDaysSecondHalf: this.employee.activity.workDaysSecondHalf,

        missionHazardAdjustment: this.missionHazardAdjustment,
        healthRiskAdjustment: this.healthRiskAdjustment,

        adjustedDaysOverGuarrantee: this.adjustedDaysOverGuarrantee,
        assignments: this.employee.assignments,
        remarks: this.reportRemarks,
      };

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.put(`/reports/monthly-activity`, report, {
          headers: { Authorization: "Bearer " + accessToken },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;

          return;
        }

        this.snackbarColor = "#66BB6A";
        this.snackbarText = `Your report was submited`;
        this.snackbarVisible = true;

        this.activityReportDialog = false;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      } finally {
        this.confirmed = false;
        this.perdiemAdjustment = 0;
        this.internationalPerdiemAdjustment = 0;
        this.missionHazardAdjustment = 0;
        this.healthRiskAdjustment = 0;
      }
    },

    async printActivityReport(employee) {
      try {
        const miniRows = [
          "Stand By",
          "Day Off",
          "Vacation",
          "Holiday",
          "Leave",
          "LOA",
        ];

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        pdfDoc.setTitle(
          `${employee.givenName.substring(0, 1)} ${
            employee.surname
          } Act-Rept  - ${this.formatDate(this.monthFilter.endDate).substring(
            3,
            11
          )}`
        );

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let day;
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(
          `${this.formatName(employee.givenName, employee.surname, "sub")} - (${
            employee.gatewayCode
          }) - ${this.formatDate(
            this.monthFilter.startDate
          )} - ${this.formatDate(this.monthFilter.endDate)}`,
          options
        );

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        //----------------------HEADER ROW 1
        options.color = rgb(0.15, 0.09, 0.81);
        options.y -= 30;
        options.x = 50;
        page.drawText(
          `Work Days 1-15: ${employee.activity.workDaysFirstHalf}`,
          options
        );

        options.x = 225;
        page.drawText(
          `Work Days 16-${employee.activity.daysInThisMonth}: ${employee.activity.workDaysSecondHalf}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Work Days Total: ${employee.activity.workDays}`,
          options
        );

        //----------------------HEADER ROW 2
        options.y -= 15;
        options.x = 50;
        page.drawText(`Vacation: ${employee.activity.vacationDays}`, options);

        options.x = 225;
        page.drawText(
          `Floating Holidays: ${employee.activity.floatingHolidayDays}`,
          options
        );

        options.x = 400;
        page.drawText(`Holidays: ${employee.activity.holidayDays}`, options);

        //----------------------HEADER ROW 3
        options.y -= 15;
        options.x = 50;
        page.drawText(`Sick: ${employee.activity.sickDays}`, options);

        options.x = 225;
        page.drawText(`LOA Days: ${employee.activity.loaDays}`, options);

        options.x = 400;
        page.drawText(`Leave: ${employee.activity.leaveDays}`, options);

        options.y -= 15;
        options.x = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Total Days: ${employee.activity.totalDays}`, options);

        options.x = 225;
        page.drawText(
          `Perdiem: ${employee.activity.combinedPerdiemDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Days off/STBY: ${employee.activity.daysOff}/${employee.activity.standByDays}`,
          options
        );

        //----------------------HEADER ROW 6
        options.y -= 15;
        options.x = 50;
        options.color = rgb(1, 0, 0);
        page.drawText(
          `Mission Hazard: ${employee.activity.missionHazard}`,
          options
        );

        options.x = 225;
        page.drawText(
          `COVID Days: ${employee.activity.healthRiskDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `COVID Allowance: ${employee.activity.healthRiskAllowance}`,
          options
        );

        //---------------------------------------------------Separator
        options.color = rgb(0, 0, 0);
        options.y -= 15;
        options.x = 50;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 15;

        let dayPerdiem;

        for (let i = 0; i < employee.activity.days.length; i++) {
          day = employee.activity.days[i];

          //---------------------------------------------------ROW 1
          // Date
          options.x = 50;
          page.drawText(`${day.date} - ${day.data.description}`, options);

          if (!miniRows.includes(day.data.description)) {
            if (day.data.internationalPerdiem > 0) {
              dayPerdiem = "Yes";
            } else if (day.data.perdiem > 0) {
              dayPerdiem = "Yes";
            } else {
              dayPerdiem = "No";
            }

            //---------------------------------------------------ROW 2
            options.y -= 15;
            // Perdiem
            options.x = 50;
            page.drawText(`Perdiem: ${dayPerdiem}`, options);
            // Hazards,
            options.x = 225;
            page.drawText(`Mission HAZ: ${day.data.missionHazard}`, options);

            // Health Risk
            options.x = 400;
            page.drawText(
              `COVID 19: ${day.data.healthRisk > 0 ? "1" : "0"}`,
              options
            );
          }
          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `_________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === employee.activity.days.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 70 < 50 && i <= employee.activity.days.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatMonth(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";

        case 9:
          return "OCT";

        case 10:
          return "NOV";

        case 11:
          return "DEC";

        default:
          return "---";
      }
    },
  },
};
</script>

<style scoped>
/* -----------------------Scrolling Board------------------ */

.report-panel {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
}

.report-panel-column {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
  background-color: #ffffff;
}

.report-panel-column-green {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
  background-color: #91e67c;
}

.remove-icon:hover {
  color: red;
}

.red-border {
  border: 1px dashed red;
}
</style>
