<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="ml-3 text-base lg:text-2xl text-white">
              Security Groups
            </div>
          </a>
        </li>

        <li class="icon" @click="getData">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li
          v-if="canCreateSecurityGroups"
          class="icon"
          @click="handleNavidateToSecurityGroup(null)"
        >
          <a href="#">
            <span class="tooltip">Add Group</span>
            <span><i class="fa-solid fa-plus"></i></span>
          </a>
        </li>

        <li>
          <RouterLink to="/"></RouterLink>
        </li>
      </ul>
    </nav>

    <!-- LOADING -->

    <r-spinner v-if="loading"> </r-spinner>

    <div v-else class="px-5 py-2">
      <div
        class="flex justify-between border-b border-gray-500 px-3 py-1 cursor-pointer"
        v-for="group in securityGroups"
        :key="group._id"
        @click="handleNavidateToSecurityGroup(group)"
      >
        <div>{{ group.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";

export default {
  name: "securityGroups",
  mixins: [mixin],
  components: { RSnackbar, RSpinner, RModal },
  data() {
    return {
      loading: false,

      inactivityTimer: null,

      securityGroups: [],

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        if (!this.auth) {
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
          return;
        }

        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
        }
        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;
      }
    },

    handleNavidateToSecurityGroup(group) {
      this.$store.commit("updateSecurityGroup", group);
      this.$router.push({ name: "securityGroup" });
    },

    async getData() {
      this.securityGroups = [];

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        const res = await api.get("/security-groups", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        const securityGroups = res.data.securityGroups;

        this.$store.commit("updateSecurityGroups", securityGroups);

        this.securityGroups = securityGroups;
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = `Error "${error}`;
        this.snackbarVisible = true;
      }
    },
  },
};
</script>
