<template>
  <div>
    <r-snackbar
      v-if="snackbarVisible"
      :backgroundColor="snackbarColor"
      :message="snackbarText"
      :timeout="3000"
      @close="snackbarVisible = false"
    >
    </r-snackbar>

    <!-- #region NAVBAR -->
    <nav>
      <ul class="nav-wrapper">
        <li class="icon">
          <RouterLink to="/">
            <span class="tooltip">Home</span>
            <span><i class="fa-solid fa-house text-white ml-3"> </i></span
          ></RouterLink>
        </li>

        <li>
          <a href="#">
            <div class="mx-2 text-base lg:text-2xl text-white">
              Activity Reports
            </div>
          </a>
        </li>

        <li>
          <a href="#">
            <button @click="handleOpenFilters" class="btn text-white">
              {{
                `${formatMonth(this.monthFilter.month)} - ${
                  this.monthFilter.year
                } - ${this.monthFilter.period} Period`
              }}
            </button>
          </a>
        </li>

        <li>
          <a href="#">
            <input
              class="mx-3 text-align-center w-24 h-8 rounded-md border-gray-500"
              @click="[(search = ''), handleFilterEmployees()]"
              v-model="search"
              @input="handleFilterEmployees()"
              type="text"
              maxlength="6"
              placeholder="Name"
            />
          </a>
        </li>

        <li @click="handleOpenFilters" class="icon">
          <a href="#">
            <span class="tooltip">Employee Filters</span>
            <span><i class="fa-solid fa-bars-filter text-yellow-500"></i></span>
          </a>
        </li>

        <li @click="handleToggleBoardStyle" class="icon">
          <a href="#">
            <span class="tooltip">Switch Board</span>
            <span><i class="fa-solid fa-bolt text-yellow-500"></i></span>
          </a>
        </li>

        <li @click="specialAirportsDialog = true" class="icon">
          <a href="#">
            <span class="tooltip">Special Airports</span>
            <span><i class="fa-solid fa-tower-control text-blue-500"></i></span>
          </a>
        </li>

        <li @click="showExcelReportsDialog = true" class="icon">
          <a href="#">
            <span class="tooltip">Payroll Reports</span>
            <span
              ><i class="fa-solid fa-file-chart-column text-orange-500 mx-1"></i
            ></span>
          </a>
        </li>

        <li @click="getData" class="icon">
          <a href="#">
            <span class="tooltip">Refresh</span>
            <span><i class="fa-solid fa-rotate"></i></span>
          </a>
        </li>

        <li>
          <a>
            <!-- <div class="txt-sm text-white mr-5 cursor-default">
              {{ `Employees: ${formatNumber(totalEmployees)}` }}
            </div> -->
          </a>
        </li>
      </ul>
    </nav>
    <!-- #endregion -->

    <!-- LOADING -->
    <r-spinner v-if="loading"> </r-spinner>

    <!-- EXCEL REPORTS DIALOG -->
    <r-modal
      v-if="showExcelReportsDialog"
      @close="showExcelReportsDialog = false"
    >
      <div class="p-5">
        <div class="flex justify-between mb-5">
          <div class="text-2xl font-bold">Payroll Reports</div>
          <button @click="showExcelReportsDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div class="border border-gray-500 rounded-md p-5 mb-2">
          <div
            class="text-2xl text-blue-500 border-b border-gray-500 pb-1 mb-5"
          >
            Flow Chart
          </div>

          <button
            class="btn bg-blue-500 text-white mb-1 mr-1"
            v-if="canGetYearlyActivityReport"
            @click="handleGetFlowChartCsv"
          >
            Export Flow Chart to CSV
          </button>
        </div>

        <div class="border border-gray-500 rounded-md p-5 mb-2">
          <div
            class="text-2xl text-blue-500 border-b border-gray-500 pb-1 mb-5"
          >
            Loadmasters
          </div>

          <div class="flex flex-wrap justify-between">
            <button
              class="btn bg-blue-500 text-white mb-1 mr-1"
              v-if="canGetYearlyActivityReport"
              @click="handleGetMonthlyActivityReport('LM')"
            >
              Export Monthly Report to CSV
            </button>

            <button
              class="btn bg-indigo-500 text-white mb-1 mr-1"
              v-if="canGetYearlyActivityReport"
              @click="handleGetYearlyActivityReport('LM')"
            >
              Export Yearly Report to CSV
            </button>

            <button
              class="btn bg-indigo-700 text-white mb-1 mr-1"
              v-if="canGetYearlyActivityReport"
              @click="handlePrintYearlyActivityReport('LM')"
            >
              Print Yearly Report
            </button>
          </div>
        </div>

        <div class="border border-gray-500 rounded-md p-5">
          <div
            class="text-2xl text-blue-500 border-b border-gray-500 pb-1 mb-5"
          >
            PSRs
          </div>

          <div class="flex flex-wrap justify-between">
            <button
              class="btn bg-blue-500 text-white mb-1 mr-1"
              v-if="canGetYearlyActivityReport"
              @click="handleGetMonthlyActivityReport('PSR')"
            >
              Export Monthly Report to CSV
            </button>

            <button
              class="btn bg-indigo-500 text-white mb-1 mr-1"
              v-if="canGetYearlyActivityReport"
              @click="handleGetYearlyActivityReport('PSR')"
            >
              Export Yearly Report to CSV
            </button>

            <button
              class="btn bg-indigo-700 text-white mb-1 mr-1"
              v-if="canGetYearlyActivityReport"
              @click="handlePrintYearlyActivityReport('PSR')"
            >
              Print Yearly Report
            </button>
          </div>
        </div>

        <div
          @click="reportsError = null"
          class="border bg-red-500 text-white rounded-md p-3 mt-3 cursor-pointer"
          v-if="reportsError"
        >
          {{ reportsError }}
        </div>
      </div>
    </r-modal>

    <!-- DAY DETAILS DIALOG -->
    <r-modal v-if="showDayDetailsDialog" @close="showDayDetailsDialog = false">
      <div class="p-5 -l2 text-sm">
        <div class="flex justify-between">
          <div class="text-2xl font-bold mb-2">
            {{
              `${formatName(
                dateData.employee.givenName,
                dateData.employee.surname,
                "l"
              )} - ${dateData.date}`
            }}
          </div>
          <button @click="showDayDetailsDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div v-if="dateData.assignments.length">
          <!-- Data Table -->
          <div class="relative overflow-x-auto">
            <table
              class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3">Assignment</th>
                  <th scope="col" class="px-6 py-3">Start</th>
                  <th scope="col" class="px-6 py-3">End</th>
                  <th scope="col" class="px-6 py-3">Assignment Type</th>
                  <th scope="col" class="px-6 py-3">Assigned By</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="assignment in dateData.assignments"
                  :key="assignment._id"
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ formatAssignmentSnapshot(assignment) }}
                  </td>
                  <td class="px-6 py-2">
                    {{ formatDateTime(assignment.startTime) }}
                  </td>
                  <td class="px-6 py-2">
                    {{ formatDateTime(assignment.endTime) }}
                  </td>
                  <td class="px-6 py-2">{{ assignment.type }}</td>
                  <td class="px-6 py-2">{{ assignment.assignedBy }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <p>No assignments</p>
        </div>
      </div>
    </r-modal>

    <!--  FILTERS DIALOG -->
    <r-modal v-if="showFiltersDialog" @close="showFiltersDialog = false">
      <div v-if="showFiltersDialog" class="p-5">
        <!-- Header -->
        <div class="flex justify-between mb-10">
          <div class="text-2xl font-bold ml-5">Employee Filters</div>
          <button @click="showFiltersDialog = false" class="btn-icon">
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>

        <div
          class="flex flex-col justify-between text-sm px-3 max-h-[300px] lg:max-h-[500px] overflow-x-auto"
        >
          <!-- Section -->
          <div class="px-5">
            <!-- Filter By Month and Period -->
            <div class="flex mb-2 border-b border-gray-500 pb-2">
              <div class="text-2xl leading-6">
                <p class="text-sm text-gray-500">Report Month</p>
                <input
                  class="block w-48 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  v-model="startDate"
                  type="month"
                />
              </div>

              <Listbox as="div" v-model="selectedPeriod" class="mb-10 ml-2">
                <p class="text-sm text-gray-500">Report Period</p>
                <div class="relative min-w-[250px]">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                  >
                    <span class="block truncate">{{ selectedPeriod }}</span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                      <ChevronUpDownIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="(option, id) in periodOptions"
                        :key="id"
                        :value="option"
                        v-slot="{ active, selectedPeriod }"
                      >
                        <li
                          :class="[
                            active ? 'bg-blue-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedPeriod ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ option }}</span
                          >

                          <span
                            v-if="selectedPeriod"
                            :class="[
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            ]"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>

            <!-- Filter by Aircraft Deck -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByAircraftDeck"
                  id="filterByAircraftDeck"
                  aria-describedby="filter-description"
                  name="filterByAircraftDeck"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label
                    for="filterByAircraftDeck"
                    class="font-medium text-gray-900"
                    >Filter by Aircraft Deck</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByAircraftDeck">
                <Listbox as="div" v-model="selectedAircraftDeck" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees when job title is associated
                    with</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{
                        selectedAircraftDeck
                      }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in aircraftDeckOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedAircraftDeck }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedAircraftDeck
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedAircraftDeck"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Company Name -->
            <div class="border-b border-gray-400 mb-5">
              <div class="flex h-6 items-center mb-2">
                <input
                  v-model="filterByCompany"
                  id="filterByCompany"
                  aria-describedby="filter-description"
                  name="filterByCompany"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                />

                <div class="ml-3 text-base leading-6">
                  <label for="filterByCompany" class="font-medium text-gray-900"
                    >Filter by Company</label
                  >
                  {{ " " }}
                </div>
              </div>

              <div class="flex my-1 px-5 pb-2" v-if="filterByCompany">
                <Listbox as="div" v-model="selectedCompany" class="mb-10">
                  <ListboxLabel
                    class="block text-sm font-medium leading-6 text-gray-900"
                    >Show Employees from this Company</ListboxLabel
                  >
                  <div class="relative mt-2 min-w-[250px]">
                    <ListboxButton
                      class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <span class="block truncate">{{ selectedCompany }}</span>
                      <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                      >
                        <ChevronUpDownIcon
                          class="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </ListboxButton>

                    <transition
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <ListboxOptions
                        class="absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        <ListboxOption
                          as="template"
                          v-for="(option, id) in companyOptions"
                          :key="id"
                          :value="option"
                          v-slot="{ active, selectedCompany }"
                        >
                          <li
                            :class="[
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            ]"
                          >
                            <span
                              :class="[
                                selectedCompany
                                  ? 'font-semibold'
                                  : 'font-normal',
                                'block truncate',
                              ]"
                              >{{ option }}</span
                            >

                            <span
                              v-if="selectedCompany"
                              :class="[
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              ]"
                            >
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                          </li>
                        </ListboxOption>
                      </ListboxOptions>
                    </transition>
                  </div>
                </Listbox>
              </div>
            </div>

            <!-- Filter by Job Title -->
            <div class="border-b border-gray-400 mb-5">
              <div class="mb-2">
                <div class="flex h-6 items-center mb-2">
                  <input
                    v-model="filterByJobTitle"
                    id="filterByJobTitle"
                    aria-describedby="filter-description"
                    name="filterJobTitle"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 cursor-pointer"
                  />

                  <div class="ml-3 text-base leading-6">
                    <label
                      for="filterByJobTitle"
                      class="font-medium text-gray-900"
                      >Filter by Job Title</label
                    >
                    {{ " " }}
                  </div>
                </div>

                <div class="flex mt-2" v-if="filterByJobTitle">
                  <div
                    v-if="selectedJobTitles.length !== jobTitles.length"
                    @click="selectedJobTitles = [...jobTitles]"
                    class="cursor-pointer text-base underline text-blue-500"
                  >
                    Selected All
                  </div>
                  <div
                    v-if="selectedJobTitles.length"
                    @click="selectedJobTitles = []"
                    class="ml-2 cursor-pointer text-base underline text-red-500"
                  >
                    Clear Selected
                  </div>
                </div>
              </div>

              <div v-if="filterByJobTitle">
                <div class="relative shadow-md">
                  <table
                    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    <tbody>
                      <tr
                        v-for="jobTitle in jobTitles"
                        :key="jobTitle.name"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td class="w-4 p-4">
                          <div class="flex items-center">
                            <input
                              v-model="selectedJobTitles"
                              :id="jobTitle.name"
                              :value="jobTitle"
                              type="checkbox"
                              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </div>
                        </td>
                        <td
                          scope="row"
                          class="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {{ jobTitle }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div class="flex justify-end mt-1">
            <button
              @click="handleSaveFilters"
              class="btn bg-blue-500 text-white"
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </r-modal>

    <!-- SPECIAL AIRPORTS DIALOG -->
    <r-modal
      v-if="specialAirportsDialog"
      @close="specialAirportsDialog = false"
    >
      <SpecialAirports @hide="specialAirportsDialog = false" />
    </r-modal>

    <!-- FLOW CHART -->
    <div v-if="boardStyle === 'Flow Chart'">
      <div class="top-frame">
        <div class="top-panel-left">
          <div class="text-base flex justify-center items-center w-[90px]">
            Employees
          </div>
        </div>
        <div class="top-panel-right" ref="topPanelRight">
          <div
            class="top-panel-right-inner"
            :style="{
              width: `${panelWidth}px`,
              left: `${scrollX}px`,
            }"
          >
            <div
              v-for="(day, index) in monthFilter.days"
              :key="index"
              class="report-top-panel-day"
              :style="{ width: `${1440 / scale}px` }"
            >
              {{ formatDay(day, scale) }}
            </div>

            <div class="flex">
              <div
                v-for="(column, index) in reportColumns"
                :key="index"
                class="report-top-panel-column-title text-[10px] text-bold"
                :style="{ width: `${columnWidth}px` }"
              >
                <div>
                  {{ column.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading">
        <div v-if="filteredEmployees.length" class="bottom-frame">
          <!-- LEFT PANEL -->
          <div class="bottom-panel-left">
            <div
              v-for="(employee, index) in filteredEmployees"
              :key="employee._id"
              class="bottom-panel-left-row text-sm hand-cursor"
              @click="saveBoardStatusAndPrintActivityReport(employee)"
            >
              <div
                class="bottom-panel-left-row-section text-truncate text-xs"
                :style="{ cursor: 'pointer' }"
              >
                {{ index + 1 }} - {{ employee.surname }},
                {{ employee.givenName.substring(0, 1) }}
              </div>

              <div class="bottom-panel-left-row-section text-truncate text-xs">
                {{ employee.companyName }} - {{ employee.jobTitle }}
              </div>
              <div class="text-xs bottom-panel-left-row-section text-truncate">
                {{ employee.gatewayCode }} - {{ employee.companyId.number }}
              </div>
            </div>

            <!-- TOTALS -->
            <div class="bottom-panel-left-row text-sm">
              <div class="bottom-panel-left-row-section text-truncate text-xs">
                Report
              </div>
              <div class="bottom-panel-left-row-section text-truncate text-xs">
                TOTALS >>>
              </div>
            </div>
          </div>

          <!-- RIGHT PANEL -->
          <div
            class="bottom-panel-right"
            @scroll="scrollHorizontal"
            ref="bottomPanelRight"
          >
            <div
              class="bottom-panel-right-inner"
              :style="{
                width: `${panelWidth}px`,
                height: `${panelHeight}px`,
              }"
            >
              <div
                v-for="employee in filteredEmployees"
                :key="employee._id"
                class="bottom-panel-right-row"
                :style="{
                  width: `${panelWidth}px`,
                }"
              >
                <div>
                  <div
                    v-for="(day, index) in employee.activity.days"
                    :key="index"
                    class="bottom-panel-day"
                    :style="{
                      width: `${1440 / scale}px`,
                      left: `${index * (1440 / scale)}px`,
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }"
                  >
                    <div
                      @click="showdayDetails(employee, day)"
                      class="text-[10px] text-bold activity-day"
                      :class="{ 'red-border': day.data.color === '#FF0000' }"
                      :style="{
                        backgroundColor: day.data.backgroundColor,
                        color: day.data.color,
                      }"
                    >
                      {{ day.data.displayCode }}
                    </div>
                  </div>

                  <!-- Work days 1 - 15 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale)}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.workDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Work days 16 - End -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) + columnWidth
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.workDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- Work days total -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 2
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-basegrey lighten-3">
                      {{ employee.activity.workDays }}
                    </div>
                  </div>

                  <!-- Vacation -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 3
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.vacationDays }}
                    </div>
                  </div>

                  <!-- Sick -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 4
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.sickDays }}
                    </div>
                  </div>

                  <!-- Days Off -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 5
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.daysOff }}
                    </div>
                  </div>

                  <!-- STBY Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 6
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.standByDays }}
                    </div>
                  </div>

                  <!-- LOA -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 7
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.loaDays }}
                    </div>
                  </div>

                  <!-- Leave -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 8
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.leaveDays }}
                    </div>
                  </div>

                  <!-- Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 9
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.holidayDays }}
                    </div>
                  </div>

                  <!-- Floating Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 10
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.floatingHolidayDays }}
                    </div>
                  </div>

                  <!-- Mission Hazardous -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 11
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-basered lighten-1">
                      {{ employee.activity.missionHazard }}
                    </div>
                  </div>

                  <!-- COVID Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 12
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.healthRiskDays }}
                    </div>
                  </div>

                  <!-- COVID Allowance -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 13
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-basered lighten-1">
                      {{ employee.activity.healthRiskAllowance }}
                    </div>
                  </div>

                  <!-- Total Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 14
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.totalDays }}
                    </div>
                  </div>

                  <!-- Days Over 20 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 15
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.daysOver20 }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 1 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 16
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.perdiemDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 2 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 17
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.perdiemDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- Days Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 18
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.daysOverGuarrantee }}
                    </div>
                  </div>

                  <!-- Hours Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 19
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base">
                      {{ employee.activity.daysOverGuarrantee * 8 }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- TOTALS -->
              <div
                class="bottom-panel-right-row"
                :style="{
                  width: `${panelWidth}px`,
                }"
              >
                <div>
                  <div
                    v-for="(day, index) in monthFilter.days"
                    :key="index"
                    class="bottom-panel-day"
                    :style="{
                      width: `${1440 / scale}px`,
                      left: `${index * (1440 / scale)}px`,
                      backgroundColor: 'white',
                      cursor: 'pointer',
                    }"
                  >
                    <div class="text-xs activity-day">---</div>
                  </div>

                  <!-- Work days 1 - 15 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${monthFilter.days.length * (1440 / scale)}px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.workDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Work days 16 - End -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) + columnWidth
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.workDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- Work days total -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 2
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div
                      class="activity-summary text-base font-bold grey lighten-2"
                    >
                      {{ summary.workDays }}
                    </div>
                  </div>

                  <!-- Vacation -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 3
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.vacationDays }}
                    </div>
                  </div>

                  <!-- Sick -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 4
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.sickDays }}
                    </div>
                  </div>

                  <!-- Days Off -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 5
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.daysOff }}
                    </div>
                  </div>

                  <!-- STBY Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 6
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.standByDays }}
                    </div>
                  </div>

                  <!-- LOA -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 7
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.loaDays }}
                    </div>
                  </div>

                  <!-- Leave -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 8
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.leaveDays }}
                    </div>
                  </div>

                  <!-- Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 9
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.holidayDays }}
                    </div>
                  </div>

                  <!-- Floating Holiday -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 10
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.floatingHolidayDays }}
                    </div>
                  </div>

                  <!-- Mission Hazardous -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 11
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base-box red lighten-1">
                      {{ summary.missionHazard }}
                    </div>
                  </div>

                  <!-- COVID Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 12
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.healthRiskDays }}
                    </div>
                  </div>

                  <!-- COVID Allowance -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 13
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base-box red lighten-1">
                      {{ summary.healthRiskAllowance }}
                    </div>
                  </div>

                  <!-- Total Days -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 14
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base-box">
                      {{ summary.totalDays }}
                    </div>
                  </div>

                  <!-- Days Over 20 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 15
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.daysOver20 }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 1 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 16
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.perdiemDaysFirstHalf }}
                    </div>
                  </div>

                  <!-- Domestic Perdiem Period 2 -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 17
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.perdiemDaysSecondHalf }}
                    </div>
                  </div>

                  <!-- Days Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 18
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.daysOverGuarrantee }}
                    </div>
                  </div>

                  <!-- Hours Over Guarrantee -->
                  <div
                    class="bottom-panel-day"
                    :style="{
                      width: `${columnWidth}px`,
                      left: `${
                        monthFilter.days.length * (1440 / scale) +
                        columnWidth * 19
                      }px`,
                      backgroundColor: 'white',
                    }"
                  >
                    <div class="activity-summary text-base font-bold">
                      {{ summary.daysOverGuarrantee * 8 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="bottom-frame text-base">
          <div class="r-frame-rounded-yellow text-blue-600">
            No employees found with the selected filters.
          </div>
        </div>
      </div>
    </div>

    <!-- REPORTS -->
    <div v-if="boardStyle === 'Reports'">
      <!-- Data Table -->
      <div class="relative overflow-x-auto">
        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-200 uppercase bg-gray-500 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th
                scope="col"
                class="px-6 py-3 cursor-pointer"
                @click="sortArrayByKey('surname')"
              >
                Last Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 cursor-pointer"
                @click="sortArrayByKey('givenName')"
              >
                First Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 cursor-pointer"
                @click="sortArrayByKey('company')"
              >
                Company
              </th>
              <th
                scope="col"
                class="px-6 py-3 cursor-pointer"
                @click="sortArrayByKey('jobTitle')"
              >
                Job Title
              </th>
              <th
                scope="col"
                class="px-6 py-3 cursor-pointer"
                @click="sortArrayByKey('companyId')"
              >
                Employee ID
              </th>
              <th scope="col" class="px-6 py-3">Employee Confirmation</th>
              <th scope="col" class="px-6 py-3">Manager Confirmation</th>
              <th scope="col" class="px-6 py-3">Administrator Confirmation</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="employee in filteredEmployees"
              :key="employee._id"
              @click="handleShowEmployeeActivityReport(employee)"
              class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
            >
              <td
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {{ employee.surname }}
              </td>
              <td class="px-6 py-2">{{ employee.givenName }}</td>
              <td class="px-6 py-2">{{ employee.companyName }}</td>
              <td class="px-6 py-2">{{ employee.jobTitle }}</td>
              <td class="px-6 py-2">{{ employee.companyId.number }}</td>
              <td class="px-6 py-2">
                {{ formatTimestamp(employee.employeeReport) }}
              </td>
              <td class="px-6 py-2">
                {{ formatTimestamp(employee.managerReport) }}
              </td>
              <td class="px-6 py-2">
                {{ formatTimestamp(employee.adminReport) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";
import authentication from "../services/authentication";
import { mixin } from "../mixins/mixin";
import exportFromJSON from "export-from-json";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import ConfirmDelete from "../components/ConfirmDelete.vue";
import SpecialAirports from "../components/SpecialAirports.vue";
import RSnackbar from "../components/RSnackbar.vue";
import RSpinner from "../components/RSpinner.vue";
import RModal from "../components/RModal.vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { previousRoute } from "../router";

export default {
  name: "reports",
  mixins: [mixin],
  components: {
    ConfirmDelete,
    SpecialAirports,
    RSnackbar,
    RSpinner,
    RModal,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronUpDownIcon,
  },

  data() {
    return {
      loading: false,
      inactivityTimer: null,
      totalEmployees: 0,

      previousRouteName: previousRoute.name,
      showDayDetailsDialog: false,
      dateData: null,
      boardStyle: "Reports",
      boardStyleOptions: ["Flow Chart", "Reports"],
      selectedBoardStyle: "Reports",
      activityReports: [],

      reportRemarks: "",
      perdiemAdjustment: 0,
      internationalPerdiemAdjustment: 0,
      missionHazardAdjustment: 0,
      healthRiskAdjustment: 0,
      adjustedMissionHazard: 0,
      adjustedHealthRisk: 0,
      search: "",
      confirmed: false,
      reportSummary: null,
      sliderX: 0,
      filteredEmployees: [],
      unfilteredEmployees: [],
      employee: null,
      scrollX: 0,

      employeeDialog: false,

      //-------------------------Snackbar
      snackbarVisible: false,
      snackbarText: "",
      snackbarColor: "white",
      snackbarTimeout: 5000,

      scale: 60,
      reportColumns: [
        { title: "Work Days 01-15", backgroundColor: "#FFFFFF" },
        { title: "Work Days 16-31", backgroundColor: "#FFFFFF" },
        { title: "Work Days Total", backgroundColor: "#FFFFFF" },
        { title: "VAC", backgroundColor: "#FFFFFF" },
        { title: "SICK", backgroundColor: "#FFFFFF" },
        { title: "OFF", backgroundColor: "#FFFFFF" },
        { title: "STBY", backgroundColor: "#FFFFFF" },
        { title: "LOA", backgroundColor: "#FFFFFF" },
        { title: "LEAVE", backgroundColor: "#FFFFFF" },
        { title: "HOL", backgroundColor: "#FFFFFF" },
        { title: "FH", backgroundColor: "#FFFFFF" },
        { title: "Mission Haz", backgroundColor: "#FFFFFF" },
        { title: "COVID 19 Days", backgroundColor: "#FFFFFF" },
        { title: "COVID 19 Allowance", backgroundColor: "#FFFFFF" },
        { title: "TOTAL DAYS", backgroundColor: "#FFFFFF" },
        { title: "DAYS OVER 20", backgroundColor: "#FFFFFF" },
        { title: "Perdiem 1", backgroundColor: "#FFFFFF" },
        { title: "Perdiem 2", backgroundColor: "#FFFFFF" },
        { title: "Days Over Guarrantee", backgroundColor: "#FFFFFF" },
        { title: "Hours Over Guarrantee", backgroundColor: "#FFFFFF" },
      ],
      columnWidth: 45,
      specialAirportsDialog: false,

      summary: null,
      selectedPeriod: "Second",
      periodOptions: ["First", "Second"],
      employeeReports: [],
      selectedReport: null,

      //-----------------------------------------Excel Reports
      reportsError: null,
      showExcelReportsDialog: false,

      //------------------------------------------Filters and Settings

      showFiltersDialog: false,

      filterByAircraftDeck: false,
      selectedAircraftDeck: null,
      aircraftDeckOptions: ["Cargo Aircraft", "Passenger Aircraft"],

      filterByCompany: false,
      selectedCompany: null,

      filterByJobTitle: false,
      selectedJobTitles: [],

      search: "",
      searchByStation: false,
      searchByStationCode: "",
      searchByStationCriteria: "Origin or Destination",
      searchByStationDialog: false,
      searchByStationOptions: [
        "Origin",
        "Destination",
        "Origin or Destination",
      ],

      startDate: null,
    };
  },

  created() {
    (async () => {
      this.getData();
    })();
  },

  mounted() {
    this.startInactivityTimer();
    document.addEventListener("click", this.resetInactivityTimer);
  },
  unmounted() {
    this.clearInactivityTimer();
    document.removeEventListener("click", this.resetInactivityTimer);
  },

  computed: {
    auth() {
      return this.$store.state.auth;
    },
    user() {
      return this.$store.state.user;
    },

    aircraftDeckFilter() {
      return this.$store.getters.aircraftDeckFilter;
    },

    companyFilter() {
      return this.$store.getters.companyFilter;
    },

    jobTitlesFilter() {
      return this.$store.getters.jobTitlesFilter;
    },

    jobTitles() {
      if (this.auth) {
        return [...this.user.securityGroup.jobTitles];
      } else {
        return [];
      }
    },

    monthFilter() {
      return this.$store.getters.monthFilter;
    },

    specialAirports() {
      let airports = [];

      this.company.specialAirports.hazardous.forEach((code) => {
        airports.push({
          category: "Hazardous",
          code,
        });
      });

      this.company.specialAirports.unhealthy.forEach((code) => {
        airports.push({
          category: "COVID 19",
          code,
        });
      });

      return airports.sort(this.compareSpecialAirports);
    },

    secondPeriod() {
      return `16 - ${this.monthFilter.days.length}`;
    },
    company() {
      return this.$store.getters.company;
    },

    airports() {
      return this.$store.state.airports;
    },

    companyOptions() {
      if (this.auth) {
        return [...this.user.securityGroup.companies];
      } else {
        return [];
      }
    },

    jobTitlesFilter() {
      return this.$store.getters.jobTitlesFilter;
    },

    panelWidth() {
      return (
        this.monthFilter.days.length * (1440 / this.scale) +
        this.reportColumns.length * this.columnWidth
      );
    },
    panelHeight() {
      return (this.filteredEmployees.length + 1) * 60 + 50;
    },

    topPanelRight() {
      return this.$refs["topPanelRight"];
    },

    bottomPanelRight() {
      return this.$refs["bottomPanelRight"];
    },

    adjustedPerdiemDaysSecondHalf() {
      //DOMESTIC PERDIEM
      let t = 0;

      if (!isNaN(this.perdiemAdjustment)) {
        t = parseInt(this.perdiemAdjustment);
      }

      if (isNaN(t)) {
        return parseInt(this.employee.activity.perdiemDaysSecondHalf);
      } else {
        return (
          parseInt(t) + parseInt(this.employee.activity.perdiemDaysSecondHalf)
        );
      }
    },

    adjustedInternationalPerdiemDaysSecondHalf() {
      //INTERNATIONAL PERDIEM
      let t = 0;

      if (!isNaN(this.internationalPerdiemAdjustment)) {
        t = parseInt(this.internationalPerdiemAdjustment);
      }

      if (isNaN(t)) {
        return parseInt(
          this.employee.activity.internationalPerdiemDaysSecondHalf
        );
      } else {
        return (
          parseInt(t) +
          parseInt(this.employee.activity.internationalPerdiemDaysSecondHalf)
        );
      }
    },

    adjustedDaysOverGuarrantee() {
      let v1 = 0;
      let v2 = 0;
      let t = 0;

      if (!isNaN(this.missionHazardAdjustment)) {
        v1 = parseInt(this.missionHazardAdjustment);
      }

      if (!isNaN(this.healthRiskAdjustment)) {
        v2 = parseFloat(this.healthRiskAdjustment);
      }

      t = v1 + v2;

      if (isNaN(t)) {
        return parseFloat(this.employee.activity.daysOverGuarrantee);
      } else {
        return (
          parseFloat(t) + parseFloat(this.employee.activity.daysOverGuarrantee)
        );
      }
    },
  },

  watch: {
    // Watch for changes in the previous route
    "previousRoute.name"(newName) {
      this.previousRouteName = newName;
    },
  },

  methods: {
    startInactivityTimer() {
      this.clearInactivityTimer();
      this.inactivityTimer = setTimeout(() => {
        this.handleAutoLogout();
      }, this.timeoutDuration);
    },

    clearInactivityTimer() {
      if (this.inactivityTimer) {
        clearTimeout(this.inactivityTimer);
      }
    },

    resetInactivityTimer() {
      this.startInactivityTimer();
    },

    async handleAutoLogout() {
      try {
        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        const res = await api.get("/auth/logout", {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.message;
          this.snackbarVisible = true;
        }
        setTimeout(() => {
          this.$store.commit("updateAuth", null);
          this.$store.commit("updateUser", null);
          clearTimeout(this.inactivityTimer);
          this.$router.push({ name: "home" });
        }, 3000);
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarVisible = true;
        this.snackbarText = `Error during logout: ${error}`;
      }
    },

    isSortedAsc(arr, key) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][key] > arr[i + 1][key]) {
          return false;
        }
      }
      return true;
    },

    sortArrayByKey(key) {
      const sortedAsc = this.isSortedAsc(this.filteredEmployees, key);

      if (sortedAsc) {
        this.filteredEmployees.sort((a, b) => (a[key] < b[key] ? 1 : -1));
      } else {
        this.filteredEmployees.sort((a, b) => (a[key] > b[key] ? 1 : -1));
      }
    },

    handleOpenFilters() {
      if (!this.selectedCompany) {
        this.selectedCompany = this.user.companyName.slice();
      }

      if (!this.selectedAircraftDeck) {
        this.selectedAircraftDeck = "Cargo Aircraft";
      }

      this.selectPeriod = this.monthFilter.period.slice();
      this.startDate = this.monthFilter.startDate.substring(0, 7);

      //-----------------------------------------Aircraft Deck Filter
      if (this.aircraftDeckFilter.active) {
        this.filterByAircraftDeck = true;
        this.selectedAircraftDeck = this.aircraftDeckFilter.deck.slice();
      }

      //----------------------------------------Company Name Filter
      if (this.companyFilter.active) {
        this.filterByCompany = true;
        this.selectedCompany = this.companyFilter.name.slice();
      }

      //----------------------------------------Job Title Filter
      if (this.jobTitlesFilter.active) {
        this.filterByJobTitle = true;
        this.selectedJobTitles = [...this.jobTitlesFilter.jobTitles];
      } else {
        this.filterByJobTitle = false;
        this.selectedJobTitles = [];
      }

      this.showFiltersDialog = true;
    },

    handleSaveFilters() {
      this.showFiltersDialog = false;

      //------------------------------------Month Filter

      const year = new Date(this.startDate).getUTCFullYear();
      const month = new Date(this.startDate).getUTCMonth();

      this.$store.commit("updateMonthFilter", {
        year,
        month,
        period: this.selectedPeriod.slice(),
      });

      //------------------------------------Aircraft Deck Filter
      let aircraftDeckFilter = {
        active: false,
        deck: "",
      };

      if (this.filterByAircraftDeck) {
        aircraftDeckFilter = {
          active: true,
          deck: this.selectedAircraftDeck.slice(),
        };
      }

      this.$store.commit("updateAircraftDeckFilter", aircraftDeckFilter);

      //-----------------------------Company Filter
      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.filterByCompany && this.selectedCompany) {
        companyFilter = {
          active: true,
          name: this.selectedCompany.slice(),
        };
      }

      this.$store.commit("updateCompanyFilter", companyFilter);

      //------------------------------Job Titles Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.filterByJobTitle && this.selectedJobTitles.length) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.selectedJobTitles],
        };
      }

      this.$store.commit("updateJobTitlesFilter", jobTitlesFilter);

      this.getData();
    },

    handleFilterEmployees() {
      this.totalEmployees = 0;
      if (this.search.length) {
        this.filteredEmployees = this.unfilteredEmployees.filter((employee) => {
          return (
            employee.surname
              .toUpperCase()
              .startsWith(this.search.toUpperCase()) ||
            employee.givenName
              .toUpperCase()
              .startsWith(this.search.toUpperCase())
          );
        });
      } else {
        this.filteredEmployees = [...this.unfilteredEmployees];
      }

      this.totalEmployees = this.filteredEmployees.length;
    },

    saveBoardStatusAndPrintActivityReport(employee) {
      this.printActivityReport(employee);
    },

    showdayDetails(employee, day) {
      this.dateData = {
        employee: { ...employee },
        date: day.date.slice(),
        ...day.data,
      };

      this.showDayDetailsDialog = true;
    },

    async getData() {
      this.unfilteredEmployees = [];
      this.filteredEmployees = [];
      let res;

      let airportFilter = {
        active: false,
      };

      //--------------------------Aircraft Deck Filter
      let aircraftDeck = "Cargo and Pax.";

      if (this.aircraftDeckFilter.active) {
        aircraftDeck = this.aircraftDeckFilter.deck.slice();
      }

      let companyFilter = {
        active: false,
        name: "",
      };

      if (this.companyFilter.active) {
        companyFilter = {
          active: true,
          name: this.companyFilter.name.slice(),
        };
      }

      //--------------------------Job Title Filter
      let jobTitlesFilter = {
        active: false,
        jobTitles: [],
      };

      if (this.jobTitlesFilter.active) {
        jobTitlesFilter = {
          active: true,
          jobTitles: [...this.jobTitlesFilter.jobTitles],
        };
      }

      try {
        //---------------------------------------Refresh Token
        this.loading = true;
        const accessToken = await authentication.getAccessToken();
        if (!accessToken) {
          this.loading = false;
          this.$router.push({ name: "home" });
          return;
        }

        res = await api.post(
          "/assignments/assignments",
          {
            dateFilterStart: this.monthFilter.start,
            dateFilterEnd: this.monthFilter.end,
            companyFilter,
            jobTitlesFilter,
            aircraftDeck,
            airportFilter,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        if (res.status !== 200) {
          this.loading = false;
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        let employees = res.data.employees;
        let year = this.monthFilter.year;
        const assignments = res.data.assignments;

        const dt = new Date(year, this.monthFilter.month, 1);

        const month = dt.getUTCMonth();

        //-----------------------------Get Reports
        res = await api.post(
          `/reports/monthly-activity/`,
          {
            month,
            year,
            period: this.monthFilter.period,
            companyFilter,
            jobTitlesFilter,
            aircraftDeck,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.snackbarColor = "red";
          this.snackbarText = res.data.message;
          this.snackbarVisible = true;
          return;
        }

        const activityReports = res.data.activityReports;

        let employeeReport;
        let managerReport;
        let adminReport;

        employees.forEach((employee) => {
          employeeReport = activityReports.find((activityReport) => {
            return (
              activityReport.employee._id === employee._id &&
              activityReport.confirmation.level === "Employee"
            );
          });
          managerReport = activityReports.find((activityReport) => {
            return (
              activityReport.employee._id === employee._id &&
              activityReport.confirmation.level === "Manager"
            );
          });
          adminReport = activityReports.find((activityReport) => {
            return (
              activityReport.employee._id === employee._id &&
              activityReport.confirmation.level === "Administrator"
            );
          });

          employee.assignments = assignments.filter((item) => {
            if (item.employee) {
              return item.employee._id === employee._id;
            }
          });

          employee.activity = this.getActivityReport(employee);
          employee.employeeReport = employeeReport;
          employee.managerReport = managerReport;
          employee.adminReport = adminReport;
        });

        this.unfilteredEmployees = [...employees];

        //------------------------------Filter Employees

        if (this.search.length) {
          this.filteredEmployees = this.unfilteredEmployees.filter(
            (employee) => {
              return employee.surname
                .toUpperCase()
                .startsWith(this.search.toUpperCase());
            }
          );
        } else {
          this.filteredEmployees = [...employees];
        }

        let summary = {
          numberOfEmployees: 0,
          daysInThisMonth: this.monthFilter.days.length,
          daysOff: 0,
          standByDays: 0,
          daysOver20: 0,
          daysOverGuarrantee: 0,
          floatingHolidayDays: 0,
          healthRiskAllowance: 0,
          healthRiskDays: 0,
          holidayDays: 0,
          leaveDays: 0,
          loaDays: 0,
          missionHazard: 0,
          perdiemDays: 0,
          perdiemDaysFirstHalf: 0,
          perdiemDaysSecondHalf: 0,
          internationalPerdiemDays: 0,
          internationalPerdiemDaysFirstHalf: 0,
          internationalPerdiemDaysSecondHalf: 0,
          sickDays: 0,
          totalDays: 0,
          vacationDays: 0,
          workDays: 0,
          workDaysFirstHalf: 0,
          workDaysSecondHalf: 0,
        };

        this.filteredEmployees.forEach(({ activity }) => {
          summary.numberOfEmployees++;
          summary.daysOff += activity.daysOff;
          summary.standByDays += activity.standByDays;
          summary.daysOver20 += activity.daysOver20;
          summary.daysOverGuarrantee += activity.daysOverGuarrantee;
          summary.floatingHolidayDays += activity.floatingHolidayDays;
          summary.healthRiskAllowance += activity.healthRiskAllowance;
          summary.healthRiskDays += activity.healthRiskDays;
          summary.holidayDays += activity.holidayDays;
          summary.leaveDays += activity.leaveDays;
          summary.loaDays += activity.loaDays;
          summary.missionHazard += activity.missionHazard;
          summary.perdiemDays += activity.perdiemDays;
          summary.perdiemDaysFirstHalf += activity.perdiemDaysFirstHalf;
          summary.perdiemDaysSecondHalf += activity.perdiemDaysSecondHalf;
          summary.internationalPerdiemDays += activity.internationalPerdiemDays;
          summary.internationalPerdiemDaysFirstHalf +=
            activity.internationalPerdiemDaysFirstHalf;
          summary.internationalPerdiemDaysSecondHalf +=
            activity.internationalPerdiemDaysSecondHalf;
          summary.sickDays += activity.sickDays;
          summary.totalDays += activity.totalDays;
          summary.vacationDays += activity.vacationDays;
          summary.workDays += activity.workDays;
          summary.workDaysFirstHalf += activity.workDaysFirstHalf;
          summary.workDaysSecondHalf += activity.workDaysSecondHalf;
        });

        this.summary = summary;

        if (this.boardStyle === "Flow Chart") {
          this.$nextTick(() => {
            this.scrollX = 0;
            this.sliderX = 0;
          });
        }
      } catch (error) {
        this.loading = false;
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    downloadCsvFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    handleGetFlowChartCsv() {
      this.reportsError = null;
      const employees = [...this.filteredEmployees];

      if (!employees.length) {
        this.reportsError = "No employees selected";
        return;
      }

      const year = this.monthFilter.year;
      const month = this.monthFilter.month;

      const fileName = `${this.formatMonth(month)}_${year}_flow-chart`;
      const exportType = exportFromJSON.types.csv;
      const data = [];

      let row;

      //PERDIEM

      let WorkDays01_15_Total = 0;
      let WorkDays16_31_Total = 0;
      let WorkDaysTotal_Total = 0;
      let VAC_Total = 0;
      let SICK_Total = 0;
      let OFF_Total = 0;
      let STBY_Total = 0;
      let LOA_Total = 0;
      let LEAVE_Total = 0;
      let HOL_Total = 0;
      let FH_Total = 0;
      let MissionHaz_Total = 0;
      let COVID19Days_Total = 0;
      let COVID19Allowance_Total = 0;
      let TOTALDAYS_Total = 0;
      let DAYSOVER20_Total = 0;
      let PerdiemPeriod1_Total = 0;
      let PerdiemPeriod2_Total = 0;
      let InternationalPerdiemPeriod1_Total = 0;
      let InternationalPerdiemPeriod2_Total = 0;
      let DaysOverGuarrantee_Total = 0;
      let HoursOverGuarrantee_Total = 0;

      employees.forEach((employee) => {
        WorkDays01_15_Total += employee.activity.workDaysFirstHalf;
        WorkDays16_31_Total += employee.activity.workDaysSecondHalf;
        WorkDaysTotal_Total += employee.activity.workDays;
        VAC_Total += employee.activity.vacationDays;
        SICK_Total += employee.activity.sickDays;
        OFF_Total += employee.activity.daysOff;
        STBY_Total += employee.activity.standByDays;
        LOA_Total += employee.activity.loaDays;
        LEAVE_Total += employee.activity.leaveDays;
        HOL_Total += employee.activity.holidayDays;
        FH_Total += employee.activity.floatingHolidayDays;
        MissionHaz_Total += employee.activity.missionHazard;
        COVID19Days_Total += employee.activity.healthRiskDays;
        COVID19Allowance_Total += employee.activity.healthRiskAllowance;
        TOTALDAYS_Total += employee.activity.totalDays;
        DAYSOVER20_Total += employee.activity.daysOver20;
        PerdiemPeriod1_Total += employee.activity.perdiemDaysFirstHalf;
        PerdiemPeriod2_Total += employee.activity.perdiemDaysSecondHalf;
        InternationalPerdiemPeriod1_Total +=
          employee.activity.internationalPerdiemDaysFirstHalf;
        InternationalPerdiemPeriod2_Total +=
          employee.activity.internationalPerdiemDaysSecondHalf;
        DaysOverGuarrantee_Total += employee.activity.daysOverGuarrantee;
        HoursOverGuarrantee_Total += employee.activity.daysOverGuarrantee * 8;

        row = {
          Employee: `${employee.surname}, ${employee.givenName}`,
          Gateway_EmployeeID: `${employee.gatewayCode} - ${employee.companyId.number}`,
        };

        for (let i = 0; i < 28; i++) {
          switch (i) {
            case 0:
              row.Day_01 = employee.activity.days[i].data.displayCode;
              break;

            case 1:
              row.Day_02 = employee.activity.days[i].data.displayCode;
              break;

            case 2:
              row.Day_03 = employee.activity.days[i].data.displayCode;
              break;
            case 3:
              row.Day_04 = employee.activity.days[i].data.displayCode;
              break;
            case 4:
              row.Day_05 = employee.activity.days[i].data.displayCode;
              break;
            case 5:
              row.Day_06 = employee.activity.days[i].data.displayCode;
              break;
            case 6:
              row.Day_07 = employee.activity.days[i].data.displayCode;
              break;
            case 7:
              row.Day_08 = employee.activity.days[i].data.displayCode;
              break;
            case 8:
              row.Day_09 = employee.activity.days[i].data.displayCode;
              break;
            case 9:
              row.Day_10 = employee.activity.days[i].data.displayCode;
              break;
            case 10:
              row.Day_11 = employee.activity.days[i].data.displayCode;
              break;
            case 11:
              row.Day_12 = employee.activity.days[i].data.displayCode;
              break;

            case 12:
              row.Day_13 = employee.activity.days[i].data.displayCode;
              break;
            case 13:
              row.Day_14 = employee.activity.days[i].data.displayCode;
              break;
            case 14:
              row.Day_15 = employee.activity.days[i].data.displayCode;
              break;
            case 15:
              row.Day_16 = employee.activity.days[i].data.displayCode;
              break;
            case 16:
              row.Day_17 = employee.activity.days[i].data.displayCode;
              break;
            case 17:
              row.Day_18 = employee.activity.days[i].data.displayCode;
              break;
            case 18:
              row.Day_19 = employee.activity.days[i].data.displayCode;
              break;
            case 19:
              row.Day_20 = employee.activity.days[i].data.displayCode;
              break;
            case 20:
              row.Day_21 = employee.activity.days[i].data.displayCode;
              break;

            case 21:
              row.Day_22 = employee.activity.days[i].data.displayCode;
              break;

            case 22:
              row.Day_23 = employee.activity.days[i].data.displayCode;
              break;
            case 23:
              row.Day_24 = employee.activity.days[i].data.displayCode;
              break;
            case 24:
              row.Day_25 = employee.activity.days[i].data.displayCode;
              break;
            case 25:
              row.Day_26 = employee.activity.days[i].data.displayCode;
              break;
            case 26:
              row.Day_27 = employee.activity.days[i].data.displayCode;
              break;
            case 27:
              row.Day_28 = employee.activity.days[i].data.displayCode;
              break;

            default:
              break;
          }
        }

        switch (employee.activity.days.length) {
          case 29:
            row.Day_29 = employee.activity.days[28].data.displayCode;
            break;

          case 30:
            row.Day_29 = employee.activity.days[28].data.displayCode;
            row.Day_30 = employee.activity.days[29].data.displayCode;

            break;

          case 31:
            row.Day_29 = employee.activity.days[28].data.displayCode;
            row.Day_30 = employee.activity.days[29].data.displayCode;
            row.Day_31 = employee.activity.days[30].data.displayCode;
            break;

          default:
            break;
        }

        row.WorkDays01_15 = employee.activity.workDaysFirstHalf;
        row.WorkDays16_31 = employee.activity.workDaysSecondHalf;
        row.WorkDaysTotal = employee.activity.workDays;
        row.VAC = employee.activity.vacationDays;
        row.SICK = employee.activity.sickDays;
        row.OFF = employee.activity.daysOff;
        row.STBY = employee.activity.standByDays;
        row.LOA = employee.activity.loaDays;
        row.LEAVE = employee.activity.leaveDays;
        row.HOL = employee.activity.holidayDays;
        row.FH = employee.activity.floatingHolidayDays;
        row.MissionHaz = employee.activity.missionHazard;
        row.COVID19Days = employee.activity.healthRiskDays;
        row.COVID19Allowance = employee.activity.healthRiskAllowance;
        row.TOTALDAYS = employee.activity.totalDays;
        row.DAYSOVER20 = employee.activity.daysOver20;
        row.PerdiemPeriod1 = employee.activity.perdiemDaysFirstHalf;
        row.PerdiemPeriod2 = employee.activity.perdiemDaysSecondHalf;
        row.InternationalPerdiemPeriod1 =
          employee.activity.internationalPerdiemDaysFirstHalf;
        row.InternationalPerdiemPeriod2 =
          employee.activity.internationalPerdiemDaysSecondHalf;
        row.DaysOverGuarrantee = employee.activity.daysOverGuarrantee;
        row.HoursOverGuarrantee = employee.activity.daysOverGuarrantee * 8;

        data.push(row);
      });

      row = {
        Employee: "Totals",
        Gateway_EmployeeID: " ",
        Day_01: " ",
        Day_02: " ",
        Day_03: " ",
        Day_04: " ",
        Day_05: " ",
        Day_06: " ",
        Day_07: " ",
        Day_08: " ",
        Day_09: " ",
        Day_10: " ",
        Day_11: " ",
        Day_12: " ",
        Day_13: " ",
        Day_14: " ",
        Day_15: " ",
        Day_16: " ",
        Day_17: " ",
        Day_18: " ",
        Day_19: " ",
        Day_20: " ",
        Day_21: " ",
        Day_22: " ",
        Day_23: " ",
        Day_24: " ",
        Day_25: " ",
        Day_26: " ",
        Day_27: " ",
        Day_28: " ",
      };

      switch (this.monthFilter.days.length) {
        case 29:
          row.Day_29 = " ";
          break;

        case 30:
          row.Day_29 = " ";
          row.Day_30 = " ";

          break;

        case 31:
          row.Day_29 = " ";
          row.Day_30 = " ";
          row.Day_31 = " ";
          break;

        default:
          break;
      }

      row.WorkDays01_15 = WorkDays01_15_Total;
      row.WorkDays16_31 = WorkDays16_31_Total;
      row.WorkDaysTotal = WorkDaysTotal_Total;
      row.VAC = VAC_Total;
      row.SICK = SICK_Total;
      row.OFF = OFF_Total;
      row.STBY = STBY_Total;
      row.LOA = LOA_Total;
      row.LEAVE = LEAVE_Total;
      row.HOL = HOL_Total;
      row.FH = FH_Total;
      row.MissionHaz = MissionHaz_Total;
      row.COVID19Days = COVID19Days_Total;
      row.COVID19Allowance = COVID19Allowance_Total;
      row.TOTALDAYS = TOTALDAYS_Total;
      row.DAYSOVER20 = DAYSOVER20_Total;
      row.PerdiemPeriod1 = PerdiemPeriod1_Total;
      row.PerdiemPeriod2 = PerdiemPeriod2_Total;
      row.InternationalPerdiemPeriod1 = InternationalPerdiemPeriod1_Total;
      row.InternationalPerdiemPeriod2 = InternationalPerdiemPeriod2_Total;
      row.DaysOverGuarrantee = DaysOverGuarrantee_Total;
      row.HoursOverGuarrantee = HoursOverGuarrantee_Total;

      data.push(row);

      exportFromJSON({ data, fileName, exportType });
    },

    handleGetMonthlyActivityReport(type) {
      this.reportsError = null;
      let jobTitles;
      let batchID;

      if (type === "LM") {
        jobTitles = [
          "Check Loadmaster",
          "Senior Loadmaster",
          "Loadmaster",
          "Loadmaster Training",
          "Contract Loadmaster",
          "Loadmaster Trainee",
          "Loadmaster/PSR",
        ];
        batchID = "LMPay-LS";
      } else {
        jobTitles = [
          "PSR",
          "Lead PSR",
          "Senior PSR",
          "Check PSR",
          "PSR Training",
          "PAA",
          "PAA Training",
          "Loadmaster/PSR",
          "PSR/PAA Trainee",
        ];
        batchID = "LPSR-LS";
      }

      const employees = this.filteredEmployees.filter((employee) => {
        return jobTitles.includes(employee.jobTitle) && employee.adminReport;
      });

      if (!employees.length) {
        this.reportsError = null;
        this.reportsError = "No reports found with the selected filters.";
        return;
      }

      const year = this.monthFilter.year;
      const month = this.monthFilter.month;

      const fileName = `${this.formatMonth(month)}_${
        this.monthFilter.period
      }_Period_${year}_activity_reports`;
      const exportType = exportFromJSON.types.csv;
      const data = [];

      let row;

      let hours3Code;
      let hours3Amount;
      let adjustDedCode;
      let adjustDedAmount;

      employees.forEach((employee) => {
        if (this.monthFilter.period === "Second") {
          if (employee.adminReport.daysOverGuarrantee > 0) {
            hours3Code = "129";
            hours3Amount = employee.adminReport.daysOverGuarrantee * 8;
          } else {
            hours3Code = " ";
            hours3Amount = " ";
          }

          if (employee.adminReport.perdiemDaysSecondHalf > 0) {
            //Domestic Perdiem
            adjustDedCode = "301";
            adjustDedAmount =
              (employee.adminReport.perdiemDaysSecondHalf +
                employee.adminReport.perdiemAdjustment) *
              75 *
              -1;
          } else {
            adjustDedCode = " ";
            adjustDedAmount = " ";
          }
        } else {
          hours3Code = " ";
          hours3Amount = " ";

          if (employee.adminReport.perdiemDaysFirstHalf > 0) {
            //Domestic Perdiem
            adjustDedCode = "301";
            adjustDedAmount =
              (employee.adminReport.perdiemDaysFirstHalf +
                employee.adminReport.perdiemAdjustment) *
              75 *
              -1;
          } else {
            adjustDedCode = " ";
            adjustDedAmount = " ";
          }
        }

        row = {
          CoCode: "K6J",
          BatchID: batchID,
          FileNumber: employee.companyId.number,
          Hours3Code: hours3Code,
          Hours3Amount: hours3Amount,
          AdjustDedCode: adjustDedCode,
          AdjustDedAmount: adjustDedAmount,
        };

        data.push(row);
      });

      exportFromJSON({ data, fileName, exportType });
    },

    async handleGetYearlyActivityReport(type) {
      let jobTitles;
      this.reportsError = null;

      if (type === "LM") {
        jobTitles = [
          "Check Loadmaster",
          "Senior Loadmaster",
          "Loadmaster",
          "Loadmaster Training",
          "Contract Loadmaster",
          "Loadmaster Trainee",
          "Loadmaster/PSR",
        ];
      } else {
        jobTitles = [
          "PSR",
          "Lead PSR",
          "Senior PSR",
          "Check PSR",
          "PSR Training",
          "PAA",
          "PAA Training",
          "Loadmaster/PSR",
          "PSR/PAA Trainee",
        ];
      }

      //--------------------------Job Title Filter
      const jobTitlesFilter = {
        active: true,
        jobTitles,
      };

      const year = this.monthFilter.year;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        //-----------------------------Get Reports
        const res = await api.post(
          `/reports/yearly-activity/`,
          {
            year,
            jobTitlesFilter,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.reportsError =
            "An error ocurred while trying to get the reports";

          return;
        }

        const activityReports = res.data.activityReports;

        const fileName = `${year}_activity_reports`;
        const exportType = exportFromJSON.types.csv;
        const data = [];

        let report;
        let row;

        let Total_MissionHazard;
        let Total_COVID19Allowance;

        let JAN_Total_MissionHazard = 0;
        let JAN_Total_COVID19Allowance = 0;

        let FEB_Total_MissionHazard = 0;
        let FEB_Total_COVID19Allowance = 0;

        let MAR_Total_MissionHazard = 0;
        let MAR_Total_COVID19Allowance = 0;

        let APR_Total_MissionHazard = 0;
        let APR_Total_COVID19Allowance = 0;

        let MAY_Total_MissionHazard = 0;
        let MAY_Total_COVID19Allowance = 0;

        let JUN_Total_MissionHazard = 0;
        let JUN_Total_COVID19Allowance = 0;

        let JUL_Total_MissionHazard = 0;
        let JUL_Total_COVID19Allowance = 0;

        let AUG_Total_MissionHazard = 0;
        let AUG_Total_COVID19Allowance = 0;

        let SEP_Total_MissionHazard = 0;
        let SEP_Total_COVID19Allowance = 0;

        let OCT_Total_MissionHazard = 0;
        let OCT_Total_COVID19Allowance = 0;

        let NOV_Total_MissionHazard = 0;
        let NOV_Total_COVID19Allowance = 0;

        let DEC_Total_MissionHazard = 0;
        let DEC_Total_COVID19Allowance = 0;

        let YEAR_Total_MissionHazard = 0;
        let YEAR_Total_COVID19Allowance = 0;

        // Note: Reports are grouped by employee, each item has an employee object and a reports array

        activityReports.forEach((item) => {
          Total_COVID19Allowance = 0;
          Total_MissionHazard = 0;

          row = {
            Employee: `${item.employee.surname}, ${item.employee.givenName}`,
            EmployeeID: item.employee.companyId.number,
          };

          for (let m = 0; m < 12; m++) {
            report = item.reports.find((r) => {
              return r.month === m;
            });

            if (report) {
              YEAR_Total_MissionHazard += report.missionHazard;
              YEAR_Total_COVID19Allowance += report.healthRiskAllowance;

              switch (m) {
                case 0:
                  row.JAN_MissionHazard = report.missionHazard;
                  row.JAN_COVID19Allowance = report.healthRiskAllowance;

                  JAN_Total_MissionHazard += report.missionHazard;
                  JAN_Total_COVID19Allowance += report.healthRiskAllowance;

                  break;
                case 1:
                  row.FEB_MissionHazard = report.missionHazard;
                  row.FEB_COVID19Allowance = report.healthRiskAllowance;

                  FEB_Total_MissionHazard += report.missionHazard;
                  FEB_Total_COVID19Allowance += report.healthRiskAllowance;

                  break;
                case 2:
                  row.MAR_MissionHazard = report.missionHazard;
                  row.MAR_COVID19Allowance = report.healthRiskAllowance;

                  MAR_Total_MissionHazard += report.missionHazard;
                  MAR_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 3:
                  row.APR_MissionHazard = report.missionHazard;
                  row.APR_COVID19Allowance = report.healthRiskAllowance;

                  APR_Total_MissionHazard += report.missionHazard;
                  APR_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 4:
                  row.MAY_MissionHazard = report.missionHazard;
                  row.MAY_COVID19Allowance = report.healthRiskAllowance;

                  MAY_Total_MissionHazard += report.missionHazard;
                  MAY_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 5:
                  row.JUN_MissionHazard = report.missionHazard;
                  row.JUN_COVID19Allowance = report.healthRiskAllowance;

                  JUN_Total_MissionHazard += report.missionHazard;
                  JUN_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 6:
                  row.JUL_MissionHazard = report.missionHazard;
                  row.JUL_COVID19Allowance = report.healthRiskAllowance;

                  JUL_Total_MissionHazard += report.missionHazard;
                  JUL_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 7:
                  row.AUG_MissionHazard = report.missionHazard;
                  row.AUG_COVID19Allowance = report.healthRiskAllowance;

                  AUG_Total_MissionHazard += report.missionHazard;
                  AUG_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 8:
                  row.SEP_MissionHazard = report.missionHazard;
                  row.SEP_COVID19Allowance = report.healthRiskAllowance;

                  SEP_Total_MissionHazard += report.missionHazard;
                  SEP_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 9:
                  row.OCT_MissionHazard = report.missionHazard;
                  row.OCT_COVID19Allowance = report.healthRiskAllowance;

                  OCT_Total_MissionHazard += report.missionHazard;
                  OCT_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 10:
                  row.NOV_MissionHazard = report.missionHazard;
                  row.NOV_COVID19Allowance = report.healthRiskAllowance;

                  NOV_Total_MissionHazard += report.missionHazard;
                  NOV_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;
                case 11:
                  row.DEC_MissionHazard = report.missionHazard;
                  row.DEC_COVID19Allowance = report.healthRiskAllowance;

                  DEC_Total_MissionHazard += report.missionHazard;
                  DEC_Total_COVID19Allowance += report.healthRiskAllowance;
                  break;

                default:
                  break;
              }

              Total_MissionHazard += report.missionHazard;
              Total_COVID19Allowance += report.healthRiskAllowance;
            } else {
              switch (m) {
                case 0:
                  row.JAN_MissionHazard = 0;
                  row.JAN_COVID19Allowance = 0;
                  break;
                case 1:
                  row.FEB_MissionHazard = 0;
                  row.FEB_COVID19Allowance = 0;

                  break;
                case 2:
                  row.MAR_MissionHazard = 0;
                  row.MAR_COVID19Allowance = 0;

                  break;
                case 3:
                  row.APR_MissionHazard = 0;
                  row.APR_COVID19Allowance = 0;

                  break;
                case 4:
                  row.MAY_MissionHazard = 0;
                  row.MAY_COVID19Allowance = 0;

                  break;
                case 5:
                  row.JUN_MissionHazard = 0;
                  row.JUN_COVID19Allowance = 0;

                  break;
                case 6:
                  row.JUL_MissionHazard = 0;
                  row.JUL_COVID19Allowance = 0;

                  break;
                case 7:
                  row.AUG_MissionHazard = 0;
                  row.AUG_COVID19Allowance = 0;

                  break;
                case 8:
                  row.SEP_MissionHazard = 0;
                  row.SEP_COVID19Allowance = 0;

                  break;
                case 9:
                  row.OCT_MissionHazard = 0;
                  row.OCT_COVID19Allowance = 0;

                  break;
                case 10:
                  row.NOV_MissionHazard = 0;
                  row.NOV_COVID19Allowance = 0;

                  break;
                case 11:
                  row.DEC_MissionHazard = 0;
                  row.DEC_COVID19Allowance = 0;

                  break;

                default:
                  break;
              }
            }
          }

          row.Total_COVID19Allowance = Total_COVID19Allowance;
          row.Total_MissionHazard = Total_MissionHazard;

          data.push(row);
        });

        row = {
          Employee: "Totals",
          EmployeeID: " ",
          JAN_MissionHazard: JAN_Total_MissionHazard,
          JAN_COVID19Allowance: JAN_Total_COVID19Allowance,
          FEB_MissionHazard: FEB_Total_MissionHazard,
          FEB_COVID19Allowance: FEB_Total_COVID19Allowance,
          MAR_MissionHazard: MAR_Total_MissionHazard,
          MAR_COVID19Allowance: MAR_Total_COVID19Allowance,
          APR_MissionHazard: APR_Total_MissionHazard,
          APR_COVID19Allowance: APR_Total_COVID19Allowance,
          MAY_MissionHazard: MAY_Total_MissionHazard,
          MAY_COVID19Allowance: MAY_Total_COVID19Allowance,
          JUN_MissionHazard: JUN_Total_MissionHazard,
          JUN_COVID19Allowance: JUN_Total_COVID19Allowance,
          JUL_MissionHazard: JUL_Total_MissionHazard,
          JUL_COVID19Allowance: JUL_Total_COVID19Allowance,
          AUG_MissionHazard: AUG_Total_MissionHazard,
          AUG_COVID19Allowance: AUG_Total_COVID19Allowance,
          SEP_MissionHazard: SEP_Total_MissionHazard,
          SEP_COVID19Allowance: SEP_Total_COVID19Allowance,
          OCT_MissionHazard: OCT_Total_MissionHazard,
          OCT_COVID19Allowance: OCT_Total_COVID19Allowance,
          NOV_MissionHazard: NOV_Total_MissionHazard,
          NOV_COVID19Allowance: NOV_Total_COVID19Allowance,
          DEC_MissionHazard: DEC_Total_MissionHazard,
          DEC_COVID19Allowance: DEC_Total_COVID19Allowance,
          Total_MissionHazard: YEAR_Total_MissionHazard,
          Total_COVID19Allowance: YEAR_Total_COVID19Allowance,
        };

        data.push(row);

        exportFromJSON({ data, fileName, exportType });
      } catch (error) {
        this.reportsError = "An error ocurred while trying to get the reports";
      }
    },

    async handlePrintYearlyActivityReport(type) {
      let jobTitles;
      this.reportsError = null;

      if (type === "LM") {
        jobTitles = [
          "Check Loadmaster",
          "Senior Loadmaster",
          "Loadmaster",
          "Loadmaster Training",
          "Contract Loadmaster",
          "Loadmaster Trainee",
          "Loadmaster/PSR",
        ];
      } else {
        jobTitles = [
          "PSR",
          "Lead PSR",
          "Senior PSR",
          "Check PSR",
          "PSR Training",
          "PAA",
          "PAA Training",
          "Loadmaster/PSR",
          "PSR/PAA Trainee",
        ];
      }

      //--------------------------Job Title Filter
      const jobTitlesFilter = {
        active: true,
        jobTitles,
      };

      const year = this.monthFilter.year;

      //---------------------------------------Refresh Token
      this.loading = true;
      const accessToken = await authentication.getAccessToken();
      if (!accessToken) {
        this.loading = false;
        this.$router.push({ name: "home" });
        return;
      }

      try {
        //-----------------------------Get Reports
        const res = await api.post(
          `/reports/yearly-activity/`,
          {
            year,
            jobTitlesFilter,
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );

        this.loading = false;

        if (res.status !== 200) {
          this.reportsError =
            "An error ocurred while trying to get the reports";

          return;
        }

        const activityReports = res.data.activityReports;

        let report;
        let totalMissionHazard;
        let totalHealthRiskAllowance;

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        const title = `Atlas Air ${year} Report`;

        pdfDoc.setTitle(title);

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(title, options);

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 20;
        let item;

        for (let i = 0; i < activityReports.length; i++) {
          item = activityReports[i];
          totalMissionHazard = 0;
          totalHealthRiskAllowance = 0;

          // Employee Name and Gateway
          options.x = 50;
          options.size = 12;
          options.color = rgb(0.15, 0.09, 0.81);

          page.drawText(
            `${item.employee.surname}, ${item.employee.givenName}`,
            options
          );

          options.color = rgb(0, 0, 0);
          options.size = 10;

          for (let m = 1; m < 13; m++) {
            options.x = 50;
            options.y -= 15;

            report = item.reports.find((r) => {
              return r.month === m;
            });

            if (report) {
              totalMissionHazard += report.missionHazard;
              totalHealthRiskAllowance += report.healthRiskAllowance;

              page.drawText(`${this.formatMonth(m - 1)} - ${year}`, options);

              options.x = 150;
              page.drawText(
                `Mission Hazard:  ${report.missionHazard.toString()} `,
                options
              );

              options.x = 350;
              page.drawText(
                `Health Risk Allowance:  ${report.healthRiskAllowance.toString()} `,
                options
              );
            } else {
              page.drawText(`${this.formatMonth(m - 1)} - ${year}`, options);

              options.x = 150;
              page.drawText(`Mission Hazard: 0 `, options);

              options.x = 350;
              page.drawText(`Health Risk Allowance: 0 `, options);
            }
          }

          options.x = 50;
          options.y -= 15;
          options.size = 12;

          page.drawText(`TOTALS - ${year}`, options);

          options.x = 150;
          page.drawText(`Mission Hazard: ${totalMissionHazard} `, options);

          options.x = 350;
          page.drawText(
            `Health Risk Allowance: ${totalHealthRiskAllowance} `,
            options
          );

          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `______________________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === activityReports.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 200 < 50 && i <= activityReports.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.reportsError =
          "An error ocurred while trying to print the reports";
      }
    },

    adjustMissionHazard() {
      if (isNaN(this.missionHazardAdjustment)) {
        this.adjustedMissionHazard = 0;
      } else {
        this.adjustedMissionHazard = parseInt(this.missionHazardAdjustment);
      }
    },

    adjustHealthRisk() {
      if (isNaN(this.healthRiskAdjustment)) {
        this.adjustedhealthRisk = 0;
      } else {
        this.adjustedhealthRisk = parseInt(this.healthRiskAdjustment);
      }
    },

    handleShowEmployeeActivityReport(employee) {
      this.$store.commit("updateEmployee", employee);
      this.$router.push({
        name: "activityReport",
      });
    },

    handleToggleBoardStyle() {
      if (this.boardStyle === "Flow Chart") {
        this.boardStyle = "Reports";
      } else {
        this.boardStyle = "Flow Chart";
      }
    },

    formatTimestamp(report) {
      if (report) {
        const d1 = new Date(report.confirmation.timestamp)
          .toUTCString()
          .toString();

        return `${d1.substring(5, 17)} - ${d1.substring(16, 22)} Z`;
      } else {
        return "Not Confirmed";
      }
    },

    scrollHorizontal(e) {
      //This fires when the horizontal scroller is used
      this.scrollX = e.target.scrollLeft * -1;
    },

    async printActivityReport(employee) {
      try {
        const miniRows = [
          "Stand By",
          "Day Off",
          "Vacation",
          "Holiday",
          "Leave",
          "LOA",
        ];

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const helveticaFontBold = await pdfDoc.embedFont(
          StandardFonts.HelveticaBold
        );

        pdfDoc.setTitle(
          `${employee.givenName.substring(0, 1)} ${
            employee.surname
          } Act-Rept  - ${this.formatDate(this.monthFilter.endDate).substring(
            3,
            11
          )}`
        );

        pdfDoc.addPage();

        let pages = pdfDoc.getPages();
        let page = pages[0];
        let day;
        let options = {
          x: 50,
          y: 775,
          size: 16,
          font: helveticaFontBold,
          color: rgb(0, 0, 0),
        };

        page.drawText(
          `${this.formatName(employee.givenName, employee.surname, "sub")} - (${
            employee.gatewayCode
          }) - ${this.formatDate(
            this.monthFilter.startDate
          )} - ${this.formatDate(this.monthFilter.endDate)}`,
          options
        );

        //---------------------------------------------------Separator
        options.size = 12;

        options.y -= 10;
        options.x = 50;
        options.font = helveticaFont;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        //----------------------HEADER ROW 1
        options.color = rgb(0.15, 0.09, 0.81);
        options.y -= 30;
        options.x = 50;
        page.drawText(
          `Work Days 1-15: ${employee.activity.workDaysFirstHalf}`,
          options
        );

        options.x = 225;
        page.drawText(
          `Work Days 16-${employee.activity.daysInThisMonth}: ${employee.activity.workDaysSecondHalf}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Work Days Total: ${employee.activity.workDays}`,
          options
        );

        //----------------------HEADER ROW 2
        options.y -= 15;
        options.x = 50;
        page.drawText(`Vacation: ${employee.activity.vacationDays}`, options);

        options.x = 225;
        page.drawText(
          `Floating Holidays: ${employee.activity.floatingHolidayDays}`,
          options
        );

        options.x = 400;
        page.drawText(`Holidays: ${employee.activity.holidayDays}`, options);

        //----------------------HEADER ROW 3
        options.y -= 15;
        options.x = 50;
        page.drawText(`Sick: ${employee.activity.sickDays}`, options);

        options.x = 225;
        page.drawText(`LOA Days: ${employee.activity.loaDays}`, options);

        options.x = 400;
        page.drawText(`Leave: ${employee.activity.leaveDays}`, options);

        options.y -= 15;
        options.x = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Total Days: ${employee.activity.totalDays}`, options);

        options.x = 225;
        page.drawText(
          `Perdiem: ${employee.activity.combinedPerdiemDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `Days off/STBY: ${employee.activity.daysOff}/${employee.activity.standByDays}`,
          options
        );

        //----------------------HEADER ROW 6
        options.y -= 15;
        options.x = 50;
        options.color = rgb(1, 0, 0);
        page.drawText(
          `Mission Hazard: ${employee.activity.missionHazard}`,
          options
        );

        options.x = 225;
        page.drawText(
          `COVID Days: ${employee.activity.healthRiskDays}`,
          options
        );

        options.x = 400;
        page.drawText(
          `COVID Allowance: ${employee.activity.healthRiskAllowance}`,
          options
        );

        //---------------------------------------------------Separator
        options.color = rgb(0, 0, 0);
        options.y -= 15;
        options.x = 50;
        page.drawText(
          `_________________________________________________________________________`,
          options
        );

        options.y -= 15;

        let dayPerdiem;

        for (let i = 0; i < employee.activity.days.length; i++) {
          day = employee.activity.days[i];

          //---------------------------------------------------ROW 1
          // Date
          options.x = 50;
          page.drawText(`${day.date} - ${day.data.description}`, options);

          if (!miniRows.includes(day.data.description)) {
            if (day.data.internationalPerdiem > 0) {
              dayPerdiem = "Yes";
            } else if (day.data.perdiem > 0) {
              dayPerdiem = "Yes";
            } else {
              dayPerdiem = "No";
            }

            //---------------------------------------------------ROW 2
            options.y -= 15;
            // Perdiem
            options.x = 50;
            page.drawText(`Perdiem: ${dayPerdiem}`, options);
            // Hazards,
            options.x = 225;
            page.drawText(`Mission HAZ: ${day.data.missionHazard}`, options);

            // Health Risk
            options.x = 400;
            page.drawText(
              `COVID 19: ${day.data.healthRisk > 0 ? "1" : "0"}`,
              options
            );
          }
          //---------------------------------------------------Separator
          options.y -= 5;
          options.x = 50;
          page.drawText(
            `_________________________________________________________________________`,
            options
          );
          options.y -= 15;

          //If last page
          if (i === employee.activity.days.length - 1) {
            options.x = 350;
            page.drawText(
              `Printed: ${this.formatDateTime(new Date())}`,
              options
            );
          }

          if (options.y - 70 < 50 && i <= employee.activity.days.length) {
            options.x = 50;
            options.y = 50;
            options.color = rgb(0, 0, 0);
            page.drawText(
              `Monarch - Powered by Raiden Solutions Inc.`,
              options
            );

            options.x = 500;
            options.color = rgb(0, 0, 0);
            page.drawText(`page ${pages.length}`, options);

            pdfDoc.addPage();
            pages = pdfDoc.getPages();
            page = pages[pages.length - 1];
            options.x = 50;
            options.y = 775;
          }
        }

        options.x = 50;
        options.y = 50;
        options.color = rgb(0, 0, 0);
        page.drawText(`Monarch - Powered by Raiden Solutions Inc.`, options);

        options.x = 500;
        page.drawText(`page ${pdfDoc.pageCount}`, options);

        const pdfUrl = URL.createObjectURL(
          new Blob([await pdfDoc.save()], { type: "application/pdf" })
        );
        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.snackbarColor = "red";
        this.snackbarText = error;
        this.snackbarVisible = true;
      }
    },

    formatAssignmentSnapshot(assignment) {
      let snapshot = "---";

      if (assignment.type !== "Flight") {
        switch (assignment.type) {
          case "Travel":
            snapshot = ` ${assignment.type} . ${assignment.originIata} - ${assignment.destinationIata}`;
            break;

          default:
            snapshot = ` ${assignment.type}  . ${assignment.originIata}`;

            break;
        }
      } else {
        snapshot = `${assignment.flightNumber} . ${assignment.aircraftRegistration} . ${assignment.originIata}-${assignment.destinationIata}`;
      }

      return snapshot;
    },

    formatMonth(m) {
      switch (m) {
        case 0:
          return "JAN";
        case 1:
          return "FEB";
        case 2:
          return "MAR";
        case 3:
          return "APR";
        case 4:
          return "MAY";
        case 5:
          return "JUN";
        case 6:
          return "JUL";
        case 7:
          return "AUG";
        case 8:
          return "SEP";
        case 9:
          return "OCT";
        case 10:
          return "NOV";
        case 11:
          return "DEC";
        default:
          return "---";
      }
    },
  },
};
</script>

<style scoped>
/* -----------------------Scrolling Board------------------ */

/* DO NOT DELETE - USED BY ACTIVITY REPORTS */
.top-frame {
  display: flex;
  top: 50px;
  width: 100%;
  min-height: 41px;
  border: 1px solid #000;
  background-color: #ebf0f3;
  z-index: 200;
}

.top-panel-left {
  display: flex;
  align-items: center;
  padding: 0 1px 0 0;
  border-right: 1px solid black;
  min-width: 101px;
  max-width: 101px;
  user-select: none;
  -webkit-user-select: none;
}

.top-panel-right {
  display: flex;
  overflow: hidden;
}

.top-panel-right-inner {
  display: flex;
  position: relative;
  left: 100px;
  height: 39px;
}

.top-panel-day {
  text-align: center;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;
  -webkit-user-select: none;
}

.bottom-frame {
  position: relative;
  display: flex;
  width: 100%;
  border: 1px solid #bebebe;
  background-color: white;
}

.bottom-panel-left {
  border-right: 1px solid black;
  background-color: #ebf0f3;
}

.bottom-panel-left-row {
  padding: 3px 0 3px 0;
  height: 60px;
  border-bottom: 1px solid black;
  width: 100px;
  user-select: none;
  -webkit-user-select: none;
}

.bottom-panel-left-row-section {
  margin: 0 3px 0 3px;
}

.bottom-panel-left-row-section:hover {
  color: blue;
}

.bottom-panel-right {
  background-color: white;
  overflow-x: scroll;
}

.bottom-panel-right-inner {
  position: relative;
  height: 39px;
  width: 1200px;
  overflow: hidden;
}

.bottom-panel-right-row {
  position: static;
  height: 60px;
  border-bottom: 1px solid black;
}

.bottom-panel-day {
  position: absolute;
  display: flex;
  height: 59px;
  border-right: 1px solid black;
}

.hour-line {
  border-right: 1px solid #f3efef;
}

.current-time {
  position: absolute;
  display: flex;
  width: 1px;
  height: 59px;
  border-right: 1px solid red;
}

/* END USED BY ACTIVITY REPORTS*/
.selection-panel {
  border: 1px solid #bebebe;
  padding: 5px;
  margin: 0 5px 0 5px;
  border-radius: 3px;
  max-height: 500px;
  overflow-y: scroll;
}

#top-banner-reports {
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 50px;
  width: 100%;
  border-bottom: 1px solid #bebebe;
  background-color: white;
  padding: 7px;
}

#top-banner-flow-chart {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 96px;
  width: 100%;
  border-bottom: 1px solid #bebebe;
  background-color: white;
}

#bottom-frame {
  position: relative;
  display: flex;
  top: 50px;
  width: 100%;
  background-color: white;
}

.report-panel {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
}

.report-panel-column {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
  background-color: #ffffff;
}

.report-panel-column-green {
  border: 1px solid #bebebe;
  padding: 5px;
  border-radius: 3px;
  background-color: #91e67c;
}

.activity-day {
  margin: 1px;
  width: 100%;
  padding: 23px 0;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

.activity-summary {
  margin: 1px;
  height: 57px;
  width: 100%;
  padding: 23px 0;
  background-color: #ffffff;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

.report-top-panel-day {
  position: static;
  padding: 10px 0;
  text-align: center;
  word-wrap: unset;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;
  -webkit-user-select: none;
}

.report-top-panel-column-title {
  position: static;
  padding: 10px 0;
  text-align: center;
  word-wrap: unset;
  height: 40px;
  border-right: 1px solid black;
  user-select: none;

  -webkit-user-select: none;
}

.remove-icon:hover {
  color: red;
}

.red-border {
  border: 1px dashed red;
}
</style>
